export enum LayerName {
  WEEDS = 'WEEDS',
  WEEDS_GRID = 'WEEDS_GRID',
  PLANTING_GAPS = 'PLANTING_GAPS',
  PRECISION_HARVEST_LINES = 'PRECISION_HARVEST_LINES',
  UNUTILIZED_AREAS = 'UNUTILIZED_AREAS',
  POTENTIAL_TRAMPLING = 'POTENTIAL_TRAMPLING',
  SUGAR_CONTENT_PREDICTION = 'SUGAR_CONTENT_PREDICTION',
  SUGAR_CONTENT_YIELD = 'SUGAR_CONTENT_YIELD',
  NEMATODES = 'NEMATODES',
  NEMATODES_1 = 'NEMATODES_1',
  NEMATODES_2 = 'NEMATODES_2',
  SSA_WEEDS = 'SSA_WEEDS',
  SSA_WEEDS_GRID = 'SSA_WEEDS_GRID',
  DRONE = 'DRONE',
  SENTINEL = 'SENTINEL',
  SENTINEL_DATE = 'SENTINEL_DATE',
  SENTINEL_NDVI = 'SENTINEL_NDVI',
  SENTINEL_NDVI_INSIDE = 'SENTINEL_NDVI_INSIDE',
  SENTINEL_DATE_INSIDE = 'SENTINEL_DATE_INSIDE',
  SENTINEL_BARE_SOIL_MAP = 'SENTINEL_BARE_SOIL_MAP',
  SATELLITE = 'SATELLITE',
  SATELLITE_PLACES = 'SATELLITE_PLACES',
  SATELLITE_ROADS = 'SATELLITE_ROADS',
  OSM = 'OSM',
  GOOGLE_SATELLITE = 'GOOGLE_SATELLITE',
  BOUNDARIES = 'BOUNDARIES',
  STATUS = 'STATUS',
  VARIETY = 'VARIETY',
  PLANTING_DATE = 'PLANTING_DATE',
  NUMBER_OF_CYCLES = 'NUMBER_OF_CYCLES',
  HARVESTED = 'HARVESTED',
  CROP_STAGE = 'CROP_STAGE',
  FARM = 'FARM',
  NEMATODES_STRESS = 'NEMATODES_STRESS',
  YIELD = 'YIELD',
  TREATMENT = 'TREATMENT',
  VIGOR_INDEX = 'VIGOR_INDEX',
  VIGOR_ZONE = 'VIGOR_ZONE',
  WEEDS_CLASSES = 'WEEDS_CLASSES',
  SSA_WEEDS_CLASSES = 'SSA_WEEDS_CLASSES',
  SOIL_EROSION = 'SOIL_EROSION',
  PARCEL_NDVI = 'PARCEL_NDVI'
}
