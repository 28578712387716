
import Menu from '@/components/Menu.vue';
import Sidebar from '@/components/Sidebar.vue';
import { Component, Vue, Watch } from 'vue-property-decorator';
import API from '@/services/api';

import { FromTo } from '@/interfaces/fromTo';
import { Unit } from '@/interfaces/unit';
import { Parcel } from '@/interfaces/parcel';
import { UnitHierarchy } from '@/interfaces/unitHierarchy';
import { FarmHierarchy } from '@/interfaces/farmHierarchy';
import { PageName } from '@/enums/pageName';
import { isAllowToUpdateAnalyticData, isAllowToUpdateFullAnalyticData } from '@/services/analyticData';
import { Farm } from '@/interfaces/farm';
import { AnalyticSummaryItem } from '@/interfaces/analyticSummaryItem';
import { stringToMomentDate } from '@/services/date';
import { Route } from 'vue-router';
import { checkSupported } from '@/services/unsupportedBrowser';
import { AuthorizeResponse } from '@/interfaces/auth';
import { LayerName } from '@/enums/layerName';
import { ProductType } from '@/enums/productType';
import { Role } from '@/enums/role';
import * as Sentry from '@sentry/vue';

@Component({
  components: {
    Menu,
    Sidebar
  }
})
export default class BaseTemplate extends Vue {
  pageName = PageName;

  private refreshSessionInterval = 600000; // millisecond, 10 min

  get currentRoute(): Route {
    return this.$route;
  }

  get fromTo(): FromTo {
    return this.$store.state.analytic.fromTo;
  }

  get selectedProductType(): ProductType {
    return this.$store.state.analytic.selectedProductType;
  }

  get selectedUnit(): Unit {
    return this.$store.state.selectedUnit;
  }

  get selectedFarm(): Farm {
    return this.$store.state.selectedFarm;
  }

  get selectedParcels(): Parcel[] {
    return this.$store.state.analytic.selectedParcels;
  }

  mounted(): void {
    if (checkSupported()) {
      API.authorize().then((response: AuthorizeResponse) => {
        if (response) {
          if (response.UserInfo && this.$root.$i18n.locale !== response.UserInfo.Language) {
            this.$root.$i18n.locale = response.UserInfo.Language;
          }
          if (response.UserInfo && !response.UserInfo.BaseMap) {
            response.UserInfo.BaseMap = LayerName.SATELLITE_PLACES;
          }
          const userInfo = response.UserInfo;
          this.$store.dispatch('setUserInfo', userInfo);
          Sentry.setUser({ email: userInfo.Email, username: userInfo.FirstName + ' ' + userInfo.LastName });
        }
      });
    }

    setInterval(() => {
      API.refreshSession();
    }, this.refreshSessionInterval);
  }

  @Watch('currentRoute')
  onCurrentRouteChanged(): void {
    this.updateAnalyticData();
  }

  @Watch('selectedFarm')
  onSelectedFarmChanged(): void {
    this.updateFarmSummary();
    if (isAllowToUpdateAnalyticData(this.$router.currentRoute.name as PageName)) {
      this.$store.dispatch('analytic/setAnalyticData', [...this.$store.state.analytic.analyticData]);
    }
    if (isAllowToUpdateFullAnalyticData(this.$router.currentRoute.name as PageName)) {
      this.$store.dispatch('analytic/setAnalyticFullData', [...this.$store.state.analytic.analyticFullData]);
    }
  }

  @Watch('fromTo')
  onFromToChanged(): void {
    this.updateAnalyticData();
  }

  @Watch('selectedProductType')
  onSelectedProductTypeChanged(): void {
    this.updateAnalyticData();
  }

  private updateFarmSummary(): void {
    if (this.$store.state.selectedFarm) {
      const currentYear = new Date().getUTCFullYear();
      API.getFarmSummary(
        this.$store.state.selectedFarm.id,
        `${currentYear - 100}-01-01`,
        `${currentYear + 100}-01-01`
      ).then((analyticSummaryItems: AnalyticSummaryItem[]) => {
        const data = (analyticSummaryItems || []).map((summaryItem: AnalyticSummaryItem) => {
          return {
            ...summaryItem,
            MomentDate: stringToMomentDate(summaryItem.Date)
          };
        });
        this.$store.dispatch('setFarmSummary', data);
      });
    } else {
      this.$store.dispatch('setFarmSummary', []);
    }
  }

  private updateAnalyticData(force = false): void {
    this.$store.dispatch('analytic/updateAnalyticData', { pageName: this.$router.currentRoute.name, force });
    this.$store.dispatch('analytic/updateAnalyticFullData', { pageName: this.$router.currentRoute.name, force });
  }

  private updateUnitHierarchy(unit: Unit): Promise<void> {
    return API.getUnitHierarchy(unit.id).then((unitHierarchy: UnitHierarchy) => {
      const parcels = [];
      if (unitHierarchy?.Farms?.length) {
        unitHierarchy.Farms.sort((a, b) => a.Name.localeCompare(b.Name));
        unitHierarchy.Farms.forEach((farm: FarmHierarchy) => {
          if (farm.Parcels && farm.Parcels.length) {
            parcels.push(...farm.Parcels);
            farm.Parcels.sort((a, b) => a.id.localeCompare(b.id));
          }
        });
      }
      this.$store.dispatch('setParcels', parcels);
      this.$store.dispatch('setSelectedUnitHierarchy', unitHierarchy);
      if (this.$store.state.preSelectedParcel) {
        const selectedParcel = this.$store.state.preSelectedParcel;
        const momentTo = stringToMomentDate(this.$store.state.analytic.fromTo.to);
        if (
          stringToMomentDate(selectedParcel.Created).isBefore(momentTo) &&
          stringToMomentDate(selectedParcel.Deleted).isAfter(momentTo)
        ) {
          this.$store.dispatch('analytic/setSelectedParcel', { parcel: selectedParcel });
        }
      }
    });
  }

  private updateUnitSummary(unit: Unit): Promise<void> {
    const currentYear = new Date().getUTCFullYear();
    return API.getUnitSummary(unit.id, `${currentYear - 100}-01-01`, `${currentYear + 100}-01-01`).then(
      (analyticSummaryItems: AnalyticSummaryItem[]) => {
        const data = (analyticSummaryItems || []).map((summaryItem: AnalyticSummaryItem) => {
          return {
            ...summaryItem,
            MomentDate: stringToMomentDate(summaryItem.Date)
          };
        });
        this.$store.dispatch('setUnitSummary', data);
      }
    );
  }

  @Watch('selectedUnit')
  onUnitChanged(unit: Unit): void {
    this.$store.dispatch('analytic/clearSelectedParcels');
    if (unit) {
      this.$store.dispatch('setSelectedUnitHierarchy', null);
      this.$store.dispatch('setIsGlobalLoaderVisible', true);
      Promise.all([this.updateUnitHierarchy(unit), this.updateUnitSummary(unit)])
        .then(() => {
          this.updateAnalyticData();
        })
        .finally(() => {
          this.$store.dispatch('setIsGlobalLoaderVisible', false);
        });
    }
  }
}
