import Vue from 'vue';
import Vuex from 'vuex';
import { createLogger } from 'vuex';

import { LayerGroupName } from '@/enums/layerGroupName';
import { LayerName } from '@/enums/layerName';
import { ProductType } from '@/enums/productType';
import { Country } from '@/interfaces/country';
import { Farm } from '@/interfaces/farm';
import { FarmHierarchy } from '@/interfaces/farmHierarchy';
import { LayerGroup } from '@/interfaces/layerGroup';
import { Organization } from '@/interfaces/organization';
import { Parcel } from '@/interfaces/parcel';
import { SowingFilter } from '@/interfaces/sowingFilter';
import { State } from '@/interfaces/state';
import { Unit } from '@/interfaces/unit';
import { UnitHierarchy } from '@/interfaces/unitHierarchy';
import { UserInfo } from '@/interfaces/userInfo';
import { WeedsClassesFilter } from '@/interfaces/weedsClassesFilter';
import { WeedsClassesSsaFilter } from '@/interfaces/weedsClassesSsaFilter';
import constants from '@/services/constants';
import { AnalyticSummaryItem } from '@/interfaces/analyticSummaryItem';
import { MapCoordinates } from '@/interfaces/mapCoordinates';
import { ParallelMapFilter } from '@/interfaces/parallelMapFilter';
import ColorHash from 'color-hash';
import { Role } from '@/enums/role';
import { analytic, analyticSecondary } from '@/store/modules/analytic';
import { ProductGroupName } from '@/enums/productGroupName';
import { isAdvancedModeEnabled } from '@/services/user';

Vue.use(Vuex);

const getAvailableProductGroupsAndLayers = (
  organization: Organization,
  defaultProductTabs: ProductGroupName[],
  userInfo: UserInfo,
  defaultLayersConfiguration
): [ProductGroupName[], LayerGroup[]] => {
  const organizationProductGroups = organization?.Products ? [...organization?.Products] : [];
  if (organizationProductGroups.includes(ProductType.SAT_ANOMALY as any)) {
    organizationProductGroups.push(ProductGroupName.GROWTH_MONITORING);
  }
  const organizationAdminProductGroups = organization?.AdminProducts ? [...organization?.AdminProducts] : [];
  if (organizationAdminProductGroups.includes(ProductType.SAT_ANOMALY)) {
    organizationAdminProductGroups.push(ProductGroupName.GROWTH_MONITORING);
  }

  let availableProductGroups = organization
    ? defaultProductTabs.filter((productGroup: ProductGroupName) => organizationProductGroups.includes(productGroup))
    : [ProductGroupName.RGB];

  const isAdmin = userInfo && userInfo.Roles && userInfo.Roles.includes(Role.ADMIN);
  if (!isAdmin && organizationAdminProductGroups.length) {
    availableProductGroups = availableProductGroups.filter(
      (productGroup: ProductGroupName) => !organizationAdminProductGroups.includes(productGroup)
    );
  }

  const availableLayerGroups = [];
  if (organization) {
    defaultLayersConfiguration.forEach((layerGroup: LayerGroup) => {
      const layers =
        layerGroup.name === LayerGroupName.ANALYTIC
          ? layerGroup.layers
          : layerGroup.layers.filter((layer: LayerName) => organization.Layers.includes(layer));

      if (layerGroup.name === LayerGroupName.FIELD_INFO && !layers.includes(LayerName.BOUNDARIES)) {
        layers.unshift(LayerName.BOUNDARIES);
      }
      if (layers.length) {
        availableLayerGroups.push({
          ...layerGroup,
          layers
        });
      }
    });
  }

  return [availableProductGroups, availableLayerGroups];
};

export default new Vuex.Store<State>({
  plugins:
    process.env.VUE_APP_DEBUG_MODE === 'true'
      ? [
          createLogger({
            actionFilter: (action) => {
              return action.type !== 'setMapCoordinates';
            },
            logMutations: false
          })
        ]
      : [],
  strict: process.env.NODE_ENV !== 'production',
  state: {
    availableProductGroups: [ProductGroupName.FIELD_INFO, ProductGroupName.RGB],
    availableLayerGroups: [],
    mapParcelFilters: {
      stages: [],
      cycles: [],
      varieties: []
    },
    sowingFilter: {},
    weedsClassesFilter: {},
    weedsClassesSsaFilter: {},
    organizations: [],
    selectedCountry: null,
    selectedFarm: null,
    selectedOrganization: null,
    selectedUnit: null,
    selectedUnitHierarchy: null,
    cacheAnalyticData: {},
    cacheAnalyticFullData: {},
    unitSummary: [],
    farmSummary: [],
    preSelectedParcel: null,
    preSelectedSurvey: null,
    parcels: {},
    mapCoordinates: null,
    userInfo: null,
    applyFilterGroundMeasurements: false,
    print: false,
    isGlobalLoaderVisible: false,
    globalLoaderCallsCounter: 0,
    printAllParcels: false,
    currentPage: 0,
    totalPages: 0,
    ndviZonesRange: [0, 1],
    soilErosionHistogramData: {},
    parallelMapFilter: {},
    ndviRange: 100,
    isSplitScreenMode: false,
    isShapeEditingMode: false
  },
  mutations: {
    setSowingFilter: (state: State, sowingFilter: SowingFilter) => {
      state.sowingFilter = sowingFilter;
    },
    setParallelMapFilter: (state: State, parallelMapFilter: ParallelMapFilter) => {
      state.parallelMapFilter = parallelMapFilter;
    },
    setWeedsClassesFilter: (state: State, weedsClassesFilter: WeedsClassesFilter) => {
      state.weedsClassesFilter = weedsClassesFilter;
    },
    setWeedsClassesSsaFilter: (state: State, weedsClassesSsaFilter: WeedsClassesSsaFilter) => {
      state.weedsClassesSsaFilter = weedsClassesSsaFilter;
    },
    setSelectedCountry: (state: State, country: Country) => {
      state.selectedCountry = country;
    },
    setSelectedFarm: (state: State, farm: Farm) => {
      state.selectedFarm = farm;
    },
    setSelectedOrganization: (state: State, organization: Organization) => {
      state.selectedOrganization = organization;
    },
    setOrganizations: (state: State, organizations: Organization[]) => {
      state.organizations = organizations;
    },
    setAvailableProductsAndGroups: (state: State, { availableProductGroups, availableLayerGroups }) => {
      state.availableProductGroups = availableProductGroups;
      state.availableLayerGroups = availableLayerGroups;
    },
    setSelectedUnit: (state: State, unit: Unit) => {
      state.selectedUnit = unit;
    },
    setMapCoordinates: (state: State, mapCoordinates: MapCoordinates) => {
      state.mapCoordinates = mapCoordinates;
    },
    clearMapParcelFilter: (state: State) => {
      state.mapParcelFilters = { stages: [], cycles: [], varieties: [] };
    },
    updateMapParcelFilter: (state: State, data: { filter: string; value: Array<string | number> }) => {
      state.mapParcelFilters = {
        ...state.mapParcelFilters,
        [data.filter]: data.value
      };
    },
    updateParcel: (state: State, parcel: Parcel) => {
      if (parcel && state.parcels[parcel.id]) {
        state.parcels[parcel.id] = parcel;
      }
    },
    setPreSelectedParcel: (state: State, { parcel, survey }) => {
      state.preSelectedParcel = parcel;
      state.preSelectedSurvey = survey;
    },
    setParcels: (state: State, parcels: Parcel[]) => {
      const parcelsById = {};
      parcels.forEach((parcel: Parcel) => {
        parcelsById[parcel.id] = parcel;
      });
      state.parcels = parcelsById;
    },
    setCacheAnalyticData: (state: State, cacheAnalyticData) => {
      state.cacheAnalyticData = cacheAnalyticData;
    },
    setCacheAnalyticFullData: (state: State, cacheAnalyticFullData) => {
      state.cacheAnalyticFullData = cacheAnalyticFullData;
    },
    setSelectedUnitHierarchy: (state: State, unitHierarchy: UnitHierarchy) => {
      state.selectedUnitHierarchy = unitHierarchy;
    },
    setUnitSummary: (state: State, summary: AnalyticSummaryItem[]) => {
      state.unitSummary = summary;
    },
    setFarmSummary: (state: State, summary: AnalyticSummaryItem[]) => {
      state.farmSummary = summary;
    },
    setUserInfo: (state: State, userInfo: UserInfo) => {
      state.userInfo = userInfo;
    },
    setApplyFilterGroundMeasurements: (state: State, applyFilterGroundMeasurements: boolean) => {
      state.applyFilterGroundMeasurements = applyFilterGroundMeasurements;
    },
    setPrint: (state: State, { print, printAllParcels }) => {
      state.print = print;
      state.printAllParcels = printAllParcels;
    },
    setPrintPages: (state: State, { currentPage, totalPages }) => {
      state.currentPage = currentPage;
      if (totalPages !== null) state.totalPages = totalPages;
    },
    setNdviZonesRange: (state: State, ndviZonesRange: [number, number]) => {
      state.ndviZonesRange = ndviZonesRange;
    },
    setSoilErosionHistogramData: (state: State, { parcelId, histogramData }) => {
      const soilErosionHistogramData = Object.assign({}, state.soilErosionHistogramData, {
        [parcelId]: histogramData
      });
      state.soilErosionHistogramData = soilErosionHistogramData;
    },
    incrementGlobalLoaderCalls: (state: State) => {
      state.globalLoaderCallsCounter = state.globalLoaderCallsCounter + 1;
      if (state.globalLoaderCallsCounter !== 0) {
        state.isGlobalLoaderVisible = true;
      }
    },
    decrementGlobalLoaderCalls: (state: State) => {
      state.globalLoaderCallsCounter = Math.max(state.globalLoaderCallsCounter - 1, 0);
      if (state.globalLoaderCallsCounter < 0) state.globalLoaderCallsCounter = 0;
      if (state.globalLoaderCallsCounter === 0) {
        state.isGlobalLoaderVisible = false;
      }
    },
    setNdviRange: (state: State, range) => {
      state.ndviRange = range;
    },
    setIsSplitScreenMode: (state: State, isSplitScreenMode: boolean) => {
      state.isSplitScreenMode = isSplitScreenMode;
    },
    setIsShapeEditingMode: (state: State, isShapeEditingMode: boolean) => {
      state.isShapeEditingMode = isShapeEditingMode;
    }
  },
  actions: {
    setSelectedCountry: ({ commit }, country: Country) => {
      localStorage.setItem(constants.LOCAL_STORAGE_KEYS.SELECTED_COUNTRY_ID, country ? country.id : null);
      commit('setSelectedCountry', country);
    },
    setSelectedFarm: ({ commit }, farm: Farm) => {
      localStorage.setItem(constants.LOCAL_STORAGE_KEYS.SELECTED_FARM_ID, farm ? farm.id : null);
      commit('setSelectedFarm', farm);
    },
    setSelectedOrganization: ({ commit, state, dispatch, getters }, { organization, pageName }) => {
      localStorage.setItem(
        constants.LOCAL_STORAGE_KEYS.SELECTED_ORGANIZATION_ID,
        organization ? organization.id : null
      );
      commit('setSelectedOrganization', organization);

      const [availableProductGroups, availableLayerGroups] = getAvailableProductGroupsAndLayers(
        organization,
        constants.defaultProductTabsRadar,
        state.userInfo,
        constants.defaultLayersConfigurationRadar
      );

      if (!availableProductGroups.includes(ProductGroupName.FIELD_INFO)) {
        availableProductGroups.push(ProductGroupName.FIELD_INFO);
      }

      if (
        availableProductGroups.includes(ProductGroupName.WEEDS) &&
        !availableProductGroups.includes(ProductGroupName.WEEDS_GRID) &&
        isAdvancedModeEnabled(state.userInfo)
      ) {
        availableProductGroups.push(ProductGroupName.WEEDS_GRID);
      }

      if (!availableProductGroups.includes(getters['analytic/selectedProductGroup'])) {
        const lastProduct = localStorage.getItem(constants.LOCAL_STORAGE_KEYS.SELECTED_PRODUCT) as ProductGroupName;
        const productToSelect = availableProductGroups.includes(lastProduct) ? lastProduct : availableProductGroups[0];
        dispatch('analytic/setProductGroup', { productGroup: productToSelect, pageName });
        dispatch('setIsSplitScreenMode', false);
      }

      commit('setAvailableProductsAndGroups', {
        availableProductGroups,
        availableLayerGroups
      });
    },
    setOrganizations: ({ commit }, organizations: Organization[]) => {
      commit('setOrganizations', organizations);
    },
    setSelectedUnit: ({ commit }, unit: Unit) => {
      localStorage.setItem(constants.LOCAL_STORAGE_KEYS.SELECTED_UNIT_ID, unit ? unit.id : null);
      commit('setSelectedUnit', unit);
    },
    clearMapParcelFilter: ({ commit }) => {
      commit('clearMapParcelFilter');
    },
    updateMapParcelFilter: ({ commit }, data: { filter: string; value: Array<string | number> }) => {
      commit('updateMapParcelFilter', data);
    },
    updateParcel: ({ commit }, parcel: Parcel) => {
      commit('updateParcel', parcel);
    },
    setPreSelectedParcel: ({ commit }, { parcel, survey }) => {
      commit('setPreSelectedParcel', { parcel, survey });
    },
    setMapCoordinates: ({ commit }, mapCoordinates: MapCoordinates) => {
      commit('setMapCoordinates', mapCoordinates);
    },
    setSelectedUnitHierarchy: ({ commit }, unitHierarchy: UnitHierarchy) => {
      constants.farmsColorMap = [];
      if (unitHierarchy && unitHierarchy.Farms && unitHierarchy.Farms.length) {
        unitHierarchy.Farms.forEach((farm: FarmHierarchy) => {
          constants.farmsColorMap.push({
            id: farm.id,
            name: farm.Name + (farm.Code ? ` (${farm.Code})` : ''),
            color: `#${Math.random().toString(16).substr(2, 6)}`
          });
        });
      }
      commit('setSelectedUnitHierarchy', unitHierarchy);
    },
    setParcels: ({ commit }, parcels: Parcel[]) => {
      constants.varietiesColorMap = {};
      const colorHash = new ColorHash();
      parcels.forEach((parcel: Parcel) => {
        if (parcel.Variety && !constants.varietiesColorMap[parcel.Variety]) {
          constants.varietiesColorMap[parcel.Variety] = colorHash.hex(parcel.Variety);
        }
      });
      commit('setParcels', parcels);
    },
    setCacheAnalyticFullData: ({ commit, state }, { key, data }) => {
      const cacheAnalyticFullData = Object.assign({}, state.cacheAnalyticFullData);
      const keys = Object.keys(cacheAnalyticFullData);
      if (keys.length > 9) {
        delete cacheAnalyticFullData[keys[0]];
      }
      cacheAnalyticFullData[key] = data;
      commit('setCacheAnalyticFullData', cacheAnalyticFullData);
    },
    setCacheAnalyticData: ({ commit, state }, { key, data }) => {
      const cacheAnalyticData = Object.assign({}, state.cacheAnalyticData);
      const keys = Object.keys(cacheAnalyticData);
      if (keys.length > 9) {
        delete cacheAnalyticData[keys[0]];
      }
      cacheAnalyticData[key] = data;
      commit('setCacheAnalyticData', cacheAnalyticData);
    },
    setUserInfo: ({ commit }, userInfo: UserInfo) => {
      commit('setUserInfo', userInfo);
    },
    updateUserInfo: ({ dispatch, state }, userInfo: Partial<UserInfo>) => {
      dispatch('setUserInfo', {
        ...state.userInfo,
        ...userInfo
      });
    },
    setUnitSummary: ({ commit }, unitSummary: AnalyticSummaryItem[]) => {
      commit('setUnitSummary', unitSummary);
    },
    setFarmSummary: ({ commit }, farmSummary: AnalyticSummaryItem[]) => {
      commit('setFarmSummary', farmSummary);
    },
    changeSowingLineVisibility: ({ commit, state }, { name, value }) => {
      const sowingFilter = Object.assign({}, state.sowingFilter);
      sowingFilter[name] = value;
      commit('setSowingFilter', sowingFilter);
    },
    changeParallelMapVisibility: ({ commit, state }, { name, value }) => {
      const parallelMapFilter = Object.assign({}, state.parallelMapFilter);
      parallelMapFilter[name] = value;
      commit('setParallelMapFilter', parallelMapFilter);
    },
    changeWeedsClassVisibility: ({ commit, state }, { name, value }) => {
      const weedsClassesFilter = Object.assign({}, state.weedsClassesFilter);
      weedsClassesFilter[name] = value;
      commit('setWeedsClassesFilter', weedsClassesFilter);
    },
    changeWeedsClassSsaVisibility: ({ commit, state }, { name, value }) => {
      const weedsClassesSsaFilter = Object.assign({}, state.weedsClassesSsaFilter);
      weedsClassesSsaFilter[name] = value;
      commit('setWeedsClassesSsaFilter', weedsClassesSsaFilter);
    },
    applyFilterGroundMeasurements: ({ commit }, applyFilterGroundMeasurements) => {
      commit('setApplyFilterGroundMeasurements', applyFilterGroundMeasurements);
    },
    setPrint: ({ commit }, { print, printAllParcels }) => {
      commit('setPrint', { print, printAllParcels });
    },
    setPrintPages: ({ commit }, { currentPage, totalPages }) => {
      commit('setPrintPages', { currentPage, totalPages });
    },
    setNdviZonesRange: ({ commit }, ndviZonesRange: [number, number]) => {
      commit('setNdviZonesRange', ndviZonesRange);
    },
    setSoilErosionHistogramData: ({ commit }, { parcelId, histogramData }) => {
      commit('setSoilErosionHistogramData', { parcelId, histogramData });
    },
    setIsGlobalLoaderVisible: ({ commit }, isVisible: boolean) => {
      if (isVisible) {
        commit('incrementGlobalLoaderCalls');
      } else {
        commit('decrementGlobalLoaderCalls');
      }
    },
    setNdviRange: ({ commit }, range: number) => {
      commit('setNdviRange', range);
    },
    setIsSplitScreenMode: ({ commit }, isSplitScreenMode: boolean) => {
      commit('setIsSplitScreenMode', isSplitScreenMode);
    },
    setIsShapeEditingMode: ({ commit }, isShapeEditingMode: boolean) => {
      commit('setIsShapeEditingMode', isShapeEditingMode);
    }
  },
  modules: {
    analytic,
    analyticSecondary
  },
  getters: {
    filteredParcelIds(state): string[] {
      const parcelIds = Object.keys(state.parcels);

      if (!state.selectedFarm) {
        return parcelIds;
      }
      const selectedFarmId = state.selectedFarm.id;
      const farms = state.selectedUnitHierarchy ? state.selectedUnitHierarchy.Farms : [];
      const farmData = farms.find((farm) => farm.id === selectedFarmId);

      if (!farmData) {
        return parcelIds;
      }

      const relatedParcelsId = farmData.Parcels.map((parcel) => parcel.id);

      return parcelIds.filter((id: string) => relatedParcelsId.includes(id));
    }
  }
});
