
import Constants from '@/services/constants';
import { Moment } from 'moment';
import { Component, Prop, Vue, Watch } from 'vue-property-decorator';

@Component({})
export default class DatePicker extends Vue {
  @Prop({ default: () => [] }) dates: string[];
  @Prop({ default: '' }) selectedDate: string;
  @Prop({ default: () => ({}) }) cloudCoverPercentageDateMap: Record<string, number>;

  public getCurrentStyle(date: Moment): any {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const style: any = {};
    if (this.dates.includes(date.format(Constants.DATE_FORMAT))) {
      style.border = '1px solid #888';
      style.borderRadius = '50%';
    }
    return style;
  }

  public getCloudPercentStyle(date: Moment): any {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const style: any = {};
    const cloudPercentage = this.cloudCoverPercentageDateMap[date.format(Constants.DATE_FORMAT)];
    if (cloudPercentage !== undefined) {
      style.background = `linear-gradient(0deg, #ccc ${cloudPercentage}%, white ${cloudPercentage}%`;
      style.border = '1px solid #ddd';
    }
    return style;
  }

  private onChangeDate(date: Moment): void {
    this.$emit('on-change-date', date.format(Constants.DATE_FORMAT));
  }
}
