export enum AnalyticType {
  RGB = 'drone',
  WEEDS = 'weeds',
  WEEDS_CLASSES = 'weeds_classes',
  PLANTING_GAPS = 'sowing',
  PRECISION_HARVEST_LINES = 'phl',
  NEMATODES = 'sat-nematodes',
  SUGAR_CONTENT_PREDICTION = 'sat-sugarcontent',
  SUGAR_CONTENT_YIELD = 'sat-sugaryield',
  VIGOR_INDEX = 'vineyard',
  SAT_WEEDS = 'sat-weeds',
  SOIL_EROSION = 'soil-erosion',
  UNUTILIZED_AREA = 'unusedArea',
  TRAMPLING_LENGTH = 'tramplingLength',
  NDVI = 'ndvi',
  LANDSAT_NDVI = 'ndvi-landsat',
  LAND_USE = 'land-use'
}
