import moment, { Moment } from 'moment';

export function stringToDate(input: string): Date {
  return input.includes('T') ? new Date(input) : new Date(`${input}T00:00:00Z`);
}

export function stringToMomentDate(input: string): Moment {
  return moment.utc(stringToDate(input));
}

export function getMomentDate(year: number, month: number, day: number): Moment {
  return moment.utc(Date.UTC(year, month, day));
}
