export enum MapLayer {
  SENTINEL_RGB = 'TRUE_COLOR',
  SENTINEL_NDVI = 'VEGETATION_INDEX',
  SENTINEL_RADAR = 'SENTINEL1-RGB',
  LANDSAT7_RGB = 'LANDSAT7-RGB',
  LANDSAT8_RGB = 'LANDSAT8-RGB',
  LANDSAT7_NDVI = 'LANDSAT7-NDVI',
  LANDSAT8_NDVI = 'LANDSAT8-NDVI',
  SENTINEL_SAVI = 'SAVI',
  SENTINEL_NDRE = 'NDRE',
  SENTINEL_NDMI = 'NDMI',
  SENTINEL_NDWI = 'NDWI',
  SATELLITE = 'satellite'
}

export enum Layers {
  SENTINEL_RGB = 'sentinelRGB',
  SENTINEL_NDVI = 'sentinelNDVI',
  SENTINEL_RADAR = 'sentinelRadar',
  SENTINEL_SAVI = 'sentinelSAVI',
  SENTINEL_NDRE = 'sentinelNDRE',
  SENTINEL_NDMI = 'sentinelNDMI',
  SENTINEL_NDWI = 'sentinelNDWI',
  SATELLITE = 'satellite',
  LANDSAT_RGB = 'landsatRGB',
  LANDSAT_NDVI = 'landsatNDVI',
  LAND_USE = 'landUse'
}
