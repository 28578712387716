
import { Component, Vue, Watch } from 'vue-property-decorator';
import LineChart from '@/components/charts/LineChart.vue';
import Mapbox from 'mapbox-gl-vue';
import * as MapboxDraw from '@mapbox/mapbox-gl-draw/dist/mapbox-gl-draw';
import API from '@/services/api';
import {
  BBox,
  Feature,
  feature,
  featureCollection,
  FeatureCollection,
  multiPolygon,
  Point,
  Polygon
} from '@turf/helpers';
import mapboxgl, { Marker, GeoJSONSource, LngLatBoundsLike } from 'mapbox-gl';
import { defaultSelectedParcelColor } from '@/services/analyticData';
import {
  LabelKeyMapping,
  LabelClassType,
  LabelClassTypeUtil,
  LayerAnalyticMapping,
  SatelliteLayerMapping,
  SatelliteURLSuffix,
  CloudMaskNameSatelliteMapping,
  LayersURLMapping,
  MapLayersCodeLayersMapping,
  LandSatRGB,
  LandSatNDVI,
  AnalyticSatelliteCloudMaskMapping
} from '@/services/constants';
import Constants from '@/services/constants';
import { FilteredDataType } from '@/services/constants';
import { Parcel } from '@/interfaces/parcel';
import { Farm } from '@/interfaces/farm';
import { Unit } from '@/interfaces/unit';
import { WeatherGrid } from '@/interfaces/weather';
import { stringToMomentDate } from '@/services/date';
import moment, { Moment } from 'moment';
import Axios from 'axios';
import turfBbox from '@turf/bbox';
import bbox from '@turf/bbox';
import turfBuffer from '@turf/buffer';
import turfCenter from '@turf/center';
import bboxPolygon from '@turf/bbox-polygon';
import { UserInfo } from '@/interfaces/userInfo';
import { Role } from '@/enums/role';
import router from '@/router';
import { PageName } from '@/enums/pageName';
import { message } from 'ant-design-vue';
import { tiles } from '@/components/Map/tile-cover.js';
import { tileToGeoJSON } from '@mapbox/tilebelt';
import { PaintMode } from '@/components/Map/paint-mode';
import simplify from '@turf/simplify';
import cleanCoords from '@turf/clean-coords';
import { drawStyles } from '@/components/Map/draw-styles';
import polygonClipping, { Ring } from 'polygon-clipping';
import { CycleEvent, LabelParcelInfo, LabelsGrid, LabelTile } from '@/interfaces/labelling';
import { Survey } from '@/interfaces/survey';
import ParcelLabels from '@/components/ParcelLabels.vue';
import LabellingForm from '@/components/LabellingForm.vue';
import buffer from '@turf/buffer';
import { getRelativePosition } from 'chart.js/helpers';
import { AnalyticSatelliteType, SatelliteType } from '@/enums/satelliteType';
import { Layers, MapLayer } from '@/enums/layerTypes';
import { AnalyticType } from '@/enums/analyticType';
import { CloudMaskLayer } from '@/enums/cloudMask';
import { AnalyticData } from '@/interfaces/analyticData';
import Timeline from '@/components/Timeline.vue';
import DatePicker from '@/components/DatePicker.vue';
import { getDatesInfoFromSelectedLayer } from '@/services/scenes';
import LayerSelector from '@/components/LayerSelector.vue';

let weatherMarkers: Marker[] = [];

@Component({
  components: {
    ParcelLabels,
    LineChart,
    Mapbox,
    LabellingForm,
    Timeline,
    DatePicker,
    LayerSelector
  }
})
export default class HarvestBenchmark extends Vue {
  chartData = { datasets: [] };
  chartOptions = {
    plugins: {
      legend: {
        display: false
      },
      tooltip: {
        callbacks: {
          label: (item) => {
            return item.raw.title || `${item.raw.y}`;
          }
        }
      }
    },
    scales: {
      x: {
        min: 0,
        max: 0,
        type: 'time',
        time: {
          tooltipFormat: Constants.DATE_FORMAT
        }
      },
      y: {
        min: -0.1,
        max: 1,
        stepSize: 0.2
      },
      y1: {
        display: false,
        position: 'right',
        min: -1,
        max: 1
      }
    },
    responsive: true,
    maintainAspectRatio: false,
    onClick: this.onChartClick.bind(this)
  };

  private basemapSourceId = 'basemapSourceId';
  private basemapLayerId = 'basemapLayerId';

  private cloudmaskSourceId = 'cloudmaskSourceId';
  private cloudmaskLayerId = 'cloudmaskLayerId';

  private parcelLayerId = 'parcelLayerId';
  private parcelSourceId = 'parcelSourceId';

  private readonly smallSquaresSourceId = 'smallSquaresSource';
  private readonly smallSquaresLayerId = 'smallSquaresLayer';

  private readonly harvestSourceId = 'harvestSourceId';
  private readonly harvestLayerId = 'harvestLayerId';

  private readonly landUseSourceId = 'landUseSourceId';
  private readonly landUseLayerId = 'landUseLayerId';

  readonly LabelClassType = LabelClassType;

  selectedLabellingClass: LabelClassType = null;

  readonly LabelKeyMapping = LabelKeyMapping;

  LAYERS = Layers;
  MIN_DAYS_BACK = 365 * 3; // 3 years
  DAYS_BACK = this.MIN_DAYS_BACK;
  parcelId = '';
  isParcelInfoVisible = false;
  isParcelInputVisible = false;
  manuallyEnteredGeojson = '';
  notes = '';
  parcel: Parcel = null;
  weatherGrid: WeatherGrid[] = [];
  farm: Farm = null;
  unit: Unit = null;
  parcelInfo = '';
  parcelLabels: any[] = [];
  dates: string[] = [];
  cloudCoverPercentageDateMap: { [key: string]: string } = {};
  cloudMaskSelected = false;
  transparency = 0; // in %
  labelsTransparency = 50; // in %
  s2Dates = [];

  isHarvestVisible = false;
  harvestAnalyticData: FeatureCollection = featureCollection([]);
  landUseAnalyticData: FeatureCollection = featureCollection([]);

  isRegionClickDisabled = false; // avoid clicking multiple times

  private map: mapboxgl.Map;
  private draw: MapboxDraw;
  skipAreaPercentage = 50;

  // labelled tiles to be fetched/stored
  labelledData: LabelsGrid = {
    zoom: 22,
    grid: {}
  };
  // grid of polygons wrapping parcel, can contain labelled polygons
  labellingSquares: Array<Feature<Polygon>> = [];
  popup: mapboxgl.Popup = null;
  private isShowDiscarded = true;
  private isEditable = false;
  private isFocusDisabled = false;
  private isMimizeSHubUsage = true;
  private isShowWeather = false;
  private isLandUse = false;
  private showImageAvailability = false;
  private landUseAnalytics = [];
  private parcelAnalyticCache = {};
  private landSatDatesTypes: Record<string, string> = {};

  public isModalVisible = false;
  public cycleEventDate: string = null;
  public startOf: string = null;
  public startOfSubClass: string = null;
  public endOf: string = null;
  public endOfSubClass: string = null;
  public events: CycleEvent[] = [];
  public drawSelectedFeatureIds: string[] = null;
  public selectedLayer = this.LAYERS.SENTINEL_RGB;
  public landsatTypeOnDate = null;
  public parcelSurveysMap: Record<string, string> = {};

  get currentMapMode(): string {
    if (this.selectedLabellingClass === LabelClassType.UNKNOWN) {
      return 'Delete Label mode (keep Shift pressed to painting)';
    }
    if (this.selectedLabellingClass) {
      return `Paint ${this.selectedLabellingClass} mode (keep Shift pressed to painting)`;
    }
    return null;
  }

  get userInfo(): UserInfo {
    return this.$store.state.userInfo;
  }

  get selectedDate(): string {
    return (this.$route.query.date as string) || moment.utc().format(Constants.DATE_FORMAT);
  }

  set selectedDate(value: string) {
    if (this.$route.query.date != value) this.$router.replace({ query: { date: value, parcelId: this.parcelId } });
  }

  @Watch('userInfo')
  onUserInfoChanged(): void {
    this.validateAccess();
  }

  @Watch('isEditable')
  onEditableChange(): void {
    if (this.isEditable) {
      this.loadLabellingData();
    } else {
      this.labelledData.grid = {};
      this.labellingSquares = [];
      this.notes = '';
      this.hideSquareGrid();
      this.stopDrawingMode();
    }
  }

  @Watch('isShowDiscarded')
  onShowDiscardedChange(): void {
    this.loadParcelDetails();
  }

  @Watch('selectedLayer')
  onSelectedLayerChanged(current, prev): void {
    if (prev === null) {
      return;
    }

    if (LayerAnalyticMapping[current] === AnalyticType.NDVI && LayerAnalyticMapping[prev] === AnalyticType.NDVI) {
      return;
    }

    const needScrolling = LayerAnalyticMapping[current] !== LayerAnalyticMapping[prev];

    this.clearChart();
    this.loadParcelDetails(needScrolling);
  }

  @Watch('selectedDate')
  async onSelectedDateChange(): Promise<void> {
    try {
      this.$store.dispatch('setIsGlobalLoaderVisible', true);
      await this.loadParcelInfoForDate();
      if (this.isEditable) {
        this.labelledData.grid = {};
        this.labellingSquares = [];
        this.notes = '';
        this.updateGeoJsonSource(this.smallSquaresSourceId, featureCollection([]));
        this.stopDrawingMode();
        await this.loadLabellingData();
      }
    } finally {
      this.$store.dispatch('setIsGlobalLoaderVisible', false);
    }
  }

  @Watch('landsatTypeOnDate')
  onLandsatTypeOnDateChanged(): void {
    this.changeLayer();
  }

  handleEditableChange(): void {
    // TODO: add confirm in case something was edited
  }

  private drawHarvest(): void {
    if (this.harvestAnalyticData) {
      this.updateGeoJsonSource(this.harvestSourceId, this.harvestAnalyticData);
    }
  }

  private drawLanduse(): void {
    if (this.landUseAnalyticData) {
      this.updateGeoJsonSource(this.landUseSourceId, this.landUseAnalyticData);
    }
  }
  private drawParcel(): void {
    if (this.parcel && this.parcel.Shape) {
      this.updateGeoJsonSource(this.parcelSourceId, this.parcel.Shape);
    }
  }

  private cleanWeatherMarkers(): void {
    weatherMarkers.forEach((marker) => {
      marker.remove();
    });
    weatherMarkers = [];
  }

  private async drawWeatherGrid(): Promise<void> {
    if (!this.map || !this.parcelCenterFeature) {
      return;
    }

    const weatherGrid = await API.getUnitWeatherData(this.selectedDate, this.selectedDate, this.unit.id);

    this.cleanWeatherMarkers();

    // draw new markers with popup info
    if (weatherGrid?.length) {
      weatherGrid.forEach((p) => {
        const html = Object.keys(p).reduce((acc, key) => `${acc}<b>${key}</b>: ${p[key]}<br/>`, '');
        const marker = new Marker()
          .setLngLat([p.GridLong, p.GridLat])
          .setPopup(
            new mapboxgl.Popup({
              closeButton: false
            }).setHTML(html)
          )
          .addTo(this.map);
        weatherMarkers.push(marker);
      });
    }
  }

  private drawData(): void {
    if (!this.map) {
      return;
    }

    const parcelBbox = turfBbox(this.parcel.Shape);
    const bufferedBbox = turfBbox(turfBuffer(bboxPolygon(parcelBbox), 0.5)) as LngLatBoundsLike;
    if (!this.isFocusDisabled) {
      this.map.fitBounds(bufferedBbox, { duration: 0 });
    }

    this.drawParcel();
  }

  private setDaysBack() {
    if (this.parcel) {
      // count amount of dates between first ndvi value and now
      let parcelAvailableDays = 0;
      if (this.parcel.Surveys) {
        let dates = this.parcel.Surveys.map((survey: Survey) => survey.Date);
        dates.sort();
        parcelAvailableDays = moment.utc().diff(stringToMomentDate(dates[0]), 'days');
      }
      this.DAYS_BACK = Math.max(parcelAvailableDays, this.MIN_DAYS_BACK);
    }
  }

  private async onLoad(): Promise<void> {
    this.parcelId = this.parcelId || (this.$route.query.parcelId as string);
    if (!this.parcelId) {
      message.error('no parcel id!', 5);
      this.$store.dispatch('setIsGlobalLoaderVisible', false);
      return;
    }

    try {
      this.$store.dispatch('setIsGlobalLoaderVisible', true);
      this.parcel = await API.getParcel(this.parcelId);
      this.loadCropCyclesEvents();
      await this.loadUnitFarmDetails();
      await this.loadParcelDetails();
      this.changeLayer();
    } catch (err) {
      message.error('Something went wrong: ' + err, 5);
    } finally {
      this.$store.dispatch('setIsGlobalLoaderVisible', false);
    }
  }

  private async loadCropCyclesEvents(): Promise<void> {
    const events = await API.getCropCycleEvents(this.parcelId);
    this.events = events ?? [];
  }

  private async loadUnitFarmDetails() {
    [this.farm, this.unit] = await Promise.all([API.getFarm(this.parcel.FarmID), API.getUnit(this.parcel.UnitID)]);
  }

  private setLandsatTypeOnDate(): void {
    this.landsatTypeOnDate = this.landSatDatesTypes[this.selectedDate];
  }

  async loadParcelDetails(needScrolling = false) {
    const from = '1900-01-01';
    const to = '2100-01-01';

    const key = `${this.parcelId}_${this.parcel.UnitID}_${to}_${from}_${LayerAnalyticMapping[this.selectedLayer]}`;

    let parcelData = this.parcelAnalyticCache[key] as Parcel;

    if (parcelData === undefined) {
      const analytic = LayerAnalyticMapping[this.selectedLayer];
      parcelData = await API.getParcelAnalytics(this.parcelId, this.parcel.UnitID, from, to, analytic);
      this.parcelAnalyticCache[key] = parcelData;
    }

    this.parcelSurveysMap = {};
    if (parcelData) {
      this.parcel.Surveys = parcelData.Surveys;
      this.parcel.Surveys?.forEach((survey: Survey) => (this.parcelSurveysMap[survey.Date] = ''));
    }

    if (!this.parcel) {
      this.dates = [];
      this.updateGeoJsonSource(this.parcelSourceId, featureCollection([]));
      throw new Error(`Can't load parcel ${this.parcelId}`);
    }

    if (this.manuallyEnteredGeojson) {
      // hack, overwrite parcel GeoJson object
      this.parcel.Shape = JSON.parse(this.manuallyEnteredGeojson);
    }
    if (this.showImageAvailability) {
      this.MIN_DAYS_BACK = moment.utc().diff(stringToMomentDate('2017-10-01'), 'days');
    } else {
      this.MIN_DAYS_BACK = 3 * 365;
    }
    this.setDaysBack();
    this.updateChartWidth(needScrolling);

    this.parcelInfo = this.prepareParcelInfo();

    this.drawData();
    await this.updateDates(SatelliteLayerMapping[this.selectedLayer]);
    if (this.isShowWeather) {
      await this.updateWeatherGrid();
    }

    await this.fetchLabels();
    await this.loadParcelInfoForDate();
  }

  private prepareParcelInfo(): string {
    const parcel: any = { ...this.parcel };
    const shape = this.parcel.Shape;
    delete parcel.Surveys;
    parcel.Unit = this.unit;
    delete parcel.Unit.Shape;
    parcel.Farm = this.farm;
    delete parcel.Farm.Shape;
    // put Shape property in the end of object
    delete parcel.Shape;
    parcel.Shape = shape;
    return JSON.stringify(parcel, null, 4);
  }

  async loadParcelInfoForDate(): Promise<void> {
    const harvestAnalyticData = await API.getHarvestAnalyticData(
      this.parcelId,
      this.selectedDate,
      new Date().getTime()
    );
    this.harvestAnalyticData = harvestAnalyticData || featureCollection([]);

    this.drawHarvest();

    if (this.isShowWeather) {
      await this.drawWeatherGrid();
    }

    this.redrawChart();

    this.$nextTick(() => {
      // wait until element appears on dom
      this.scrollToSelectedDate();
    });
  }

  private get parcelCenterFeature(): Feature<Point> {
    return this.parcel ? turfCenter(this.parcel.Shape) : null;
  }

  async mounted(): Promise<void> {
    this.validateAccess();
    await this.onLoad();

    document.onkeydown = (e) => {
      if (this.isEditable) {
        if (e.code == 'Escape') {
          return this.exitLabellingMode();
        }
        // find a label class for key pressed
        const labelClass = Object.keys(LabelKeyMapping).find((key) => {
          if ('Digit' + LabelKeyMapping[key] === e.code) {
            return key;
          }
        });
        if (labelClass) {
          this.paintLabelClass(labelClass as LabelClassType);
        }
      }
    };
  }

  private validateAccess(): void {
    if (this.userInfo && !this.userInfo.Roles?.includes(Role.ADMIN)) {
      // TODO: replace to page
      router.push(PageName.RADAR);
    }
  }

  async updateDates(satelliteType: string): Promise<void> {
    const { dates, cloudCoverPercentageDateMap, sourceDateMap } = await getDatesInfoFromSelectedLayer(
      this.DAYS_BACK,
      satelliteType,
      this.parcelBbox
    );

    if (satelliteType === SatelliteType.LANDSAT_7 || satelliteType === SatelliteType.LANDSAT_8) {
      this.landSatDatesTypes = sourceDateMap;
      this.setLandsatTypeOnDate();
    }

    this.cloudCoverPercentageDateMap = cloudCoverPercentageDateMap;

    this.dates = dates;
  }

  onMapLoaded(map: mapboxgl.Map): void {
    this.map = map;
    this.map.keyboard.disable();
    this.popup = new mapboxgl.Popup({ closeButton: false, closeOnClick: false });
    this.createParcelLayer();
    this.createSmallSquaresLayer();
    this.createDrawLayer();
    this.createHarvestLayer();
    this.createLandUseLayer();
    this.hideHarvest();
    this.changeLayer();
  }
  async onParcelSelected(parcelId: string) {
    this.parcelId = parcelId;
    try {
      this.$store.dispatch('setIsGlobalLoaderVisible', true);
      this.parcel = await API.getParcel(this.parcelId);
      await this.loadParcelDetails();
      this.changeLayer();
    } catch (err) {
      message.error('Something went wrong: ' + err, 5);
    } finally {
      this.$store.dispatch('setIsGlobalLoaderVisible', false);
    }
  }

  private createParcelLayer(): void {
    this.map.addSource(this.parcelSourceId, {
      type: 'geojson',
      data: featureCollection([])
    });

    this.map.addLayer({
      id: this.parcelLayerId,
      type: 'line',
      source: this.parcelSourceId,
      paint: {
        'line-color': defaultSelectedParcelColor,
        'line-width': 3
      }
    });
  }

  private createSmallSquaresLayer(): void {
    this.map.addSource(this.smallSquaresSourceId, {
      type: 'geojson',
      data: featureCollection([])
    });
    this.map.addLayer({
      type: 'line',
      id: this.smallSquaresLayerId,
      source: this.smallSquaresSourceId,
      paint: {
        'line-width': 1,
        'line-color': ['get', 'color'],
        'line-opacity': this.labelsTransparency / 100
      }
    });
  }

  private simplifyPolygon(polygon: Feature<Polygon>): Feature<Polygon> {
    const cv = cleanCoords(polygon);
    return simplify(cv, { tolerance: 0.000001 });
  }

  private createDrawLayer(): void {
    const paintMode = new PaintMode();
    paintMode.onDrawFinished = async (polygon: Feature<Polygon>) => {
      this.map.getCanvas().style.cursor = 'progress';
      setTimeout(() => {
        if (!polygon) {
          return;
        }

        const simplifiedPolygon = this.simplifyPolygon(polygon);

        if (this.selectedLabellingClass === LabelClassType.UNKNOWN) {
          this.deleteSelectedFeatures(simplifiedPolygon);
        }

        if (this.selectedLabellingClass && this.selectedLabellingClass !== LabelClassType.UNKNOWN) {
          this.markSelectedFeatures(simplifiedPolygon);
        }

        setTimeout(() => {
          this.map.getCanvas().style.cursor = 'crosshair';
        }, 300);
      }, 100);
    };

    this.draw = new MapboxDraw({
      userProperties: true,
      displayControlsDefault: false,
      styles: drawStyles,
      modes: {
        paint: paintMode,
        ...MapboxDraw.modes
      }
    });
    this.map.addControl(this.draw);
  }

  selectDate(date: string): void {
    this.selectedDate = date;
    this.onChangeDate();
  }

  public chartStyle = {
    height: '100%',
    pointerEvents: 'auto',
    width: '100%'
  };

  private updateChartWidth(needScrolling: boolean): void {
    let chartWidth = '100%';
    if (this.parcel?.Surveys?.length) {
      const surveys = this.parcel.Surveys.map((survey: Survey) => survey.Date).sort();
      const firstDate = stringToMomentDate(surveys[0]);
      const lastDate = stringToMomentDate(surveys[surveys.length - 1]);

      const months = Math.ceil(lastDate.diff(firstDate, 'months', true));
      const monthWidth = 25;

      const container = (this.$refs.pageContainer as HTMLDivElement)?.getBoundingClientRect();
      const width = months * monthWidth;
      if (width > container?.width) {
        chartWidth = `${(width * 100) / container.width}%`;
      }
    }

    this.chartStyle.width = chartWidth;

    if (needScrolling) {
      window.setTimeout(() => {
        const elements = document.getElementsByClassName('chart');
        const chart = elements[0];
        if (chart) {
          chart.scrollTo({ left: chart.scrollWidth, behavior: 'smooth' });
        }
      }, 2000);
    }
  }

  redrawChart(): void {
    this.clearChart();
    if (!this.manuallyEnteredGeojson || this.isLandUse) {
      this.createChart();
    }
  }

  private async updateWeatherGrid(): Promise<void> {
    if (this.dates.length && this.parcelCenterFeature) {
      this.weatherGrid = await API.getParcelWeatherData(
        this.dates[0],
        this.dates[this.dates.length - 1],
        this.parcelCenterFeature.geometry.coordinates[1],
        this.parcelCenterFeature.geometry.coordinates[0]
      );
    }
  }

  public async changeShowImageAvailability(): Promise<void> {
    await this.loadParcelDetails();
    this.redrawChart();
  }

  public async changeShowLandUse(): Promise<void> {
    this.landUseAnalytics = [];
    if (this.isLandUse) {
      const landUse = await API.getParcelAnalytics(
        this.parcelId,
        this.parcel.UnitID,
        '1990-01-01',
        '2100-01-01',
        'land-use'
      );

      if (landUse.Surveys) {
        landUse.Surveys.forEach((survey: Survey) => {
          this.landUseAnalytics.push({ SurveyDate: survey.Date, Value: survey.SurveyAnalytic[0].Value });
        });
      }
    } else {
      this.hideLandUse();
    }
    this.redrawChart();
  }

  public async changeShowWeather(): Promise<void> {
    if (this.isShowWeather) {
      await this.$store.dispatch('setIsGlobalLoaderVisible', true);
      await Promise.all([this.updateWeatherGrid(), this.drawWeatherGrid()]);
      await this.$store.dispatch('setIsGlobalLoaderVisible', false);
    } else {
      this.weatherGrid = [];
      this.cleanWeatherMarkers();
    }
    this.redrawChart();
  }

  private createChart(): void {
    this.chartData = {
      datasets: this.getDataSetsForParcel()
    };
    if (this.parcel) {
      const dates = [
        ...(this.parcel.Surveys ? this.parcel.Surveys.map((survey: Survey) => survey.Date) : []),
        ...(this.parcel.HarvestDates || []),
        ...this.landUseAnalytics.map((x) => x.SurveyDate)
      ];
      let minDate = moment.utc().subtract(this.DAYS_BACK, 'days');
      dates.forEach((date: string) => {
        const momentDate = stringToMomentDate(date);
        if (momentDate.isBefore(minDate)) {
          minDate = momentDate;
        }
      });
      this.chartOptions.scales.x.min = minDate.unix() * 1000;
      this.chartOptions.scales.x.max = moment.utc().startOf('day').unix() * 1000;
    }
    if (this.weatherGrid?.length) {
      const precipitation = this.weatherGrid.map((weather: WeatherGrid) => weather.Precipitation);
      this.chartOptions.scales.y1.min = Math.min(
        ...precipitation,
        ...this.weatherGrid.map((weather: WeatherGrid) => weather.TempMin)
      );
      this.chartOptions.scales.y1.max = Math.max(
        ...precipitation,
        ...this.weatherGrid.map((weather: WeatherGrid) => weather.TempMax)
      );
    }
    this.chartOptions.scales.y1.display = this.isShowWeather;
  }

  private getDataset(type: FilteredDataType): any {
    let chartData = [];
    let color = 'green';
    let borderColor = 'green';
    if (this.parcel.Surveys) {
      this.parcel.Surveys.sort((a, b) => a.Date.localeCompare(b.Date));
      chartData = this.parcel.Surveys.map((survey: Survey) => {
        const data = survey.SurveyAnalytic[0];
        return {
          x: stringToMomentDate(survey.Date),
          y:
            data.RawValue !== undefined && data.Value === Constants.IS_CLOUDY
              ? data.RawValue
              : data.Value === Constants.IS_CLOUDY
              ? 0
              : data.Value,
          isClickablePoint: true,
          isRadarNDVI: data.Satellite === Constants.SENTINEL_1,
          isCloudy: data.Value === Constants.IS_CLOUDY,
          hasRawValue: data.RawValue !== undefined
        };
      });

      switch (type) {
        case FilteredDataType.Ndvi:
          chartData = chartData.filter((item) => item.y > 0 && !item.hasRawValue);
          break;
        case FilteredDataType.Cloudy:
          chartData = chartData.filter((item) => item.isCloudy && !item.hasRawValue);
          color = '#aaaaaa';
          borderColor = '#aaaaaa';
          break;
        case FilteredDataType.OutlierFiltered:
          chartData = chartData.filter((item) => item.hasRawValue);
          color = 'blue';
          borderColor = 'blue';
          break;
        default:
          break;
      }
    }

    if (!chartData.length) {
      return null;
    }

    return {
      datalabels: {
        labels: {
          title: null
        }
      },
      type: type === FilteredDataType.OutlierFiltered || type === FilteredDataType.Cloudy ? 'scatter' : 'line',
      isRadarNDVI: (context) => context.dataset.data[context.dataIndex].isRadarNDVI,
      borderColor: borderColor,
      borderWidth: 1,
      pointRadius: (context) => this.getPointRadius(context),
      pointBackgroundColor: (context) => this.getPointBackgroundColor(context, color, type),
      fill: false,
      tension: 0,
      data: chartData
    };
  }

  private getPointRadius(context: any) {
    const item = context.dataset.data[context.dataIndex];
    return item.isRadarNDVI || item.isCloudy ? 3 : 2.6;
  }

  private getPointBackgroundColor(context: any, color: string, type: any) {
    const item = context.dataset.data[context.dataIndex];
    if (type === FilteredDataType.Cloudy) {
      return color;
    }
    if (type === FilteredDataType.OutlierFiltered) {
      return item.isRadarNDVI ? 'white' : 'blue';
    }
    return item.isRadarNDVI ? 'white' : 'green';
  }

  private getDataSetsForParcel(): any[] {
    let datasets = [];
    // display parcel labels
    datasets = [...datasets, ...this.getParcelLabelsDatasets()];
    // show harvest dates

    let parcelDataset = this.parcel ? this.getDataset(FilteredDataType.Ndvi) : null;
    if (parcelDataset) {
      // const harvests = this.parcel.PartialHarvest
      //   ? [...this.parcel.PartialHarvest, ...(this.parcel.HarvestDates || [])]
      //   : this.parcel.HarvestDates || [];
      const harvests = this.parcel.HarvestDates || [];
      harvests.forEach((currentYearHarvest) => {
        const redLineDataset = this.getVerticalLineDataset(currentYearHarvest, 'red');
        datasets.push(redLineDataset);
      });
      datasets = [...datasets, parcelDataset];
    }

    if (this.isShowDiscarded) {
      const outlierDataset = this.parcel ? this.getDataset(FilteredDataType.OutlierFiltered) : null;
      if (outlierDataset) {
        datasets = [...datasets, outlierDataset];
      }
      const cloudyDataset = this.parcel ? this.getDataset(FilteredDataType.Cloudy) : null;
      if (cloudyDataset) {
        datasets = [...datasets, cloudyDataset];
      }
    }

    if (this.showImageAvailability) {
      datasets = [...datasets, this.getSatelliteAvailabilityDataset()];
    }
    //cycle events
    datasets = [...datasets, ...this.getCycleEventDatasets(), ...this.setLabellingTimelineDataset()];
    // parcel information
    datasets = [...datasets, ...this.getParcelSeasonsDataset()];
    // display current date
    datasets.push(this.getVerticalLineDataset(stringToMomentDate(this.selectedDate), 'green', 2));
    // display parcel planted date
    datasets.push(this.getVerticalLineDataset(this.parcel.Planted, 'blue'));
    if (this.weatherGrid?.length) {
      datasets = [...datasets, this.getWeatherPrecipitationDataset(), ...this.getWeatherTempDataset()];
    }
    let minDate = this.chartOptions.scales.x.min;
    if (this.isLandUse && this.landUseAnalytics.length) {
      this.landUseAnalytics.forEach((landUse: AnalyticData) => {
        const surveyDate = stringToMomentDate(landUse.SurveyDate);
        datasets.push(this.getVerticalLineDataset(surveyDate, this.getLandUseColor(landUse.Value), 2));
        if (surveyDate.unix() * 1000 < minDate) {
          this.chartOptions.scales.x.min = surveyDate.unix() * 1000;
        }
        datasets.push({
          backgroundColor: this.getLandUseColor(landUse.Value),
          borderColor: this.getLandUseColor(landUse.Value),
          borderWidth: 2,
          pointStyle: 'crossRot',
          pointRadius: 5,
          fill: false,
          tension: 0,
          data: [
            {
              x: stringToMomentDate(landUse.SurveyDate),
              y: 0.97,
              title: [`Land use class: ${Constants.landUseClasses[landUse.Value]}`],
              isLandUse: true
            }
          ]
        });
      });
    }
    return datasets;
  }
  private getSatelliteAvailabilityDataset() {
    const chartData = this.dates
      .filter((x) => parseFloat(this.cloudCoverPercentageDateMap[x]) < 100)
      .map((date) => {
        return {
          x: stringToMomentDate(date),
          y: 0,
          isClickablePoint: true,
          isRadarNDVI: false,
          isCloudy: false,
          hasRawValue: false
        };
      });
    return {
      datalabels: {
        labels: {
          title: null
        }
      },
      type: 'scatter',
      isRadarNDVI: false,
      borderColor: '#aaaaaa',
      borderWidth: 1,
      pointRadius: 3,
      pointBackgroundColor: '#aaaaaa',
      fill: false,
      tension: 0,
      data: chartData
    };
  }
  private getLandUseColor(value: number): string {
    return Constants.landUseColorMap[value];
  }
  private setLabellingTimelineDataset(): any[] {
    const firstDate = moment.utc().subtract(this.DAYS_BACK, 'days').format(Constants.DATE_FORMAT);

    const defaultEvents: CycleEvent[] = [
      {
        date: firstDate,
        parcelId: this.parcelId
      },
      {
        date: moment.utc().format(Constants.DATE_FORMAT),
        parcelId: this.parcelId
      }
    ];

    return [
      {
        borderColor: '#4dc9f6',
        borderWidth: 7,
        pointBackgroundColor: 'transparent',
        pointRadius: 0,
        pointBorderWidth: 0,
        pointHoverRadius: 0,
        pointHoverBorderWidth: 0,
        pointHoverBackgroundColor: 'transparent',
        fill: false,
        tension: 1,
        data: defaultEvents.map((event: CycleEvent) => {
          return {
            x: stringToMomentDate(event.date),
            y: -0.05,
            title: null
          };
        })
      }
    ];
  }

  private getCycleEventDatasets(): any[] {
    const events = [...(this.events ?? [])];

    return [
      {
        backgroundColor: '#ffffff',
        borderColor: '#4dc9f6',
        borderWidth: 7,
        pointBackgroundColor: '#ffffff',
        pointBorderColor: '#4dc9f6',
        pointRadius: 6,
        pointBorderWidth: 0.5,
        pointHoverRadius: 6,
        pointHoverBorderWidth: 0.5,
        pointHoverBackgroundColor: '#0979a5',
        pointHoverBorderColor: '#ffffff',
        fill: true,
        showLine: false,
        data: events.map((event: CycleEvent) => {
          return {
            x: stringToMomentDate(event.date),
            y: -0.05,
            isCycleEvent: true,
            startOf: event.startOf,
            startOfSubClass: event.startOfSubClass,
            endOf: event.endOf,
            endOfSubClass: event.endOfSubClass,
            title: [
              `StartOf: ${event.startOf ?? ''}`,
              `SubClass: ${event.startOfSubClass ?? ''}`,
              `EndOf: ${event.endOf ?? ''}`,
              `SubClass: ${event.endOfSubClass ?? ''}`
            ]
          };
        })
      }
    ];
  }

  private getWeatherTempDataset(): any[] {
    return [
      {
        backgroundColor: 'orange',
        borderColor: 'orange',
        borderWidth: 1,
        pointRadius: 1,
        pointHitRadius: 1,
        pointHoverBorderWidth: 1,
        pointHoverRadius: 1,
        fill: false,
        tension: 0,
        data: this.weatherGrid.map((weather: WeatherGrid) => {
          return {
            x: stringToMomentDate(weather.Date),
            y: weather.TempMin
          };
        }),
        yAxisID: 'y1'
      },
      {
        backgroundColor: 'red',
        borderColor: 'red',
        borderWidth: 1,
        pointRadius: 1,
        pointHitRadius: 1,
        pointHoverBorderWidth: 1,
        pointHoverRadius: 1,
        fill: false,
        tension: 0,
        data: this.weatherGrid.map((weather: WeatherGrid) => {
          return {
            x: stringToMomentDate(weather.Date),
            y: weather.TempMax
          };
        }),
        yAxisID: 'y1'
      }
    ];
  }

  private getWeatherPrecipitationDataset() {
    return {
      backgroundColor: 'blue',
      data: this.weatherGrid.map((weather: WeatherGrid) => {
        return {
          x: stringToMomentDate(weather.Date),
          y: weather.Precipitation
        };
      }),
      type: 'bar',
      yAxisID: 'y1'
    };
  }

  private getParcelSeasonsDataset(): any {
    const color = 'gray';
    const dataset = [];
    //Add dummy season for parcels without seasons
    if (!this.parcel.Seasons || Object.keys(this.parcel.Seasons).length === 0) {
      this.parcel.Seasons = { [`${new Date().getFullYear()}`]: {} };
    }
    // display parcel events on the graph with tooltip
    Object.keys(this.parcel.Seasons).forEach((season) => {
      const cuStage = this.parcel.Seasons[season].CutStage ?? this.parcel.CutStage;
      const fieldName = [0, 1].includes(cuStage) ? 'Planted' : 'LastHarvestDate';
      const date = this.parcel.Seasons[season][fieldName];
      if (date) {
        const momentDate = stringToMomentDate(date);
        dataset.push(this.getVerticalLineDataset(date, color));
        dataset.push({
          backgroundColor: color,
          borderColor: color,
          borderWidth: 3,
          pointRadius: 3,
          fill: false,
          tension: 0,
          data: [
            {
              x: momentDate,
              y: 1,
              title: [`${fieldName}: ${momentDate.format('YYYY-MM-DD')}`, `Season: ${season}`, `Cut Stage: ${cuStage}`]
            }
          ]
        });
      }
    });
    return dataset;
  }

  private getLabelPoint(color, date, title, offset): any {
    return {
      type: 'scatter',
      backgroundColor: color,
      borderColor: color,
      borderWidth: 3,
      pointRadius: 3,
      pointStyle: 'rect',
      fill: false,
      tension: 0,
      data: [
        {
          x: stringToMomentDate(date),
          y: 1 - offset * 0.03, // to have several labels partially visible
          title,
          isClickablePoint: true
        }
      ]
    };
  }

  private getParcelLabelsDatasets(): any {
    const datasets = [];
    if (this.parcelLabels && this.parcelLabels.length > 0) {
      this.parcelLabels.forEach((item) => {
        const labels = [...(item.labels || [])].sort();
        let i = 0;
        labels.forEach((label) => {
          const color = LabelClassTypeUtil.getLabelClassColor(label);
          datasets.push(this.getLabelPoint(color, item.date, label, i));
          i++;
        });
        if (item.notes) {
          const notesColor = '#f5d742';
          datasets.push(this.getLabelPoint(notesColor, item.date, `${this.$t('note')}: ${item.notes}`, i));
        }
      });
    }
    return datasets;
  }

  private getVerticalLineDataset(x: any, color: string, width = 1, y = 0) {
    return {
      datalabels: {
        labels: {
          title: null
        }
      },
      backgroundColor: color,
      borderColor: color,
      borderWidth: width,
      pointRadius: 0,
      pointHitRadius: 0,
      pointHoverBorderWidth: 0,
      pointHoverRadius: 0,
      fill: false,
      data: [
        {
          x,
          y
        },
        {
          x,
          y: 1
        }
      ]
    };
  }

  private clearChart(): void {
    if (this.chartData) {
      this.chartData = { datasets: [] };
    }
  }

  private updateGeoJsonSource(sourceId: string, geoJsonData: FeatureCollection): void {
    const source = this.map.getSource(sourceId) as GeoJSONSource;
    if (source) {
      source.setData(geoJsonData as any);
    }
  }

  private onChangeDate(): void {
    this.setLandsatTypeOnDate();
    this.changeLayer();
  }

  private scrollToSelectedDate(): void {
    window.setTimeout(() => {
      const elements = document.getElementsByClassName('ant-radio-button-wrapper-checked');
      if (elements && elements.length) {
        elements[0].scrollIntoView({ behavior: 'smooth', inline: 'center' });
      }
    }, 500);
  }
  private async showLandUse(date: string): Promise<void> {
    const landUseAnalyticData = await API.getLandUseAnalyticData(this.parcelId, date, new Date().getTime());
    if (landUseAnalyticData) {
      this.landUseAnalyticData = landUseAnalyticData;
      this.drawLanduse();
    }
  }
  private onChartClick(e, arr): void {
    if (arr && arr.length) {
      const element = arr[0];
      const data = e.chart.data.datasets[element.datasetIndex].data[element.index];
      if (data?.isLandUse) {
        this.showLandUse(data.x.format(Constants.DATE_FORMAT));
        this.selectedDate = data.x.format(Constants.DATE_FORMAT);
        this.onChangeDate();
      }
      if (data?.isClickablePoint) {
        this.selectedDate = data.x.format(Constants.DATE_FORMAT);
        this.onChangeDate();
        this.scrollToSelectedDate();
      }

      if (data?.isCycleEvent) {
        this.cycleEventDate = (data.x as Moment).format(Constants.DATE_FORMAT);
        this.startOf = data.startOf;
        this.startOfSubClass = data.startOfSubClass;
        this.endOf = data.endOf;
        this.endOfSubClass = data.endOfSubClass;
        this.isModalVisible = true;
      }
    } else {
      const canvasPosition = getRelativePosition(e, e.chart);
      const dataX = e.chart.scales.x.getValueForPixel(canvasPosition.x);
      const clickValueMoment = moment(dataX).format(Constants.DATE_FORMAT);

      const datasets = this.getDataSetsForParcel();
      datasets.push(this.getVerticalLineDataset(clickValueMoment, '#4dc9f6', 2, -0.05));

      this.chartData = {
        datasets: datasets
      };

      this.cycleEventDate = clickValueMoment;
      this.isModalVisible = true;
    }
  }

  public async handleActionEvent(eventData: CycleEvent, action: string) {
    if (action === 'submit') {
      this.events.push(eventData);
      await API.createOrUpdateCropCycleEvent(eventData);
    }
    if (action === 'update') {
      this.events = this.events.filter((event) => event.date != this.cycleEventDate);
      this.events.push(eventData);
      await API.deleteCropCycleEvent(this.parcelId, this.cycleEventDate);
      await API.createOrUpdateCropCycleEvent(eventData);
    }
    if (action === 'delete') {
      this.events = this.events.filter((event) => event.date != eventData.date);
      await API.deleteCropCycleEvent(this.parcelId, eventData.date);
    }
    this.closeForm();
  }

  public closeForm() {
    this.isModalVisible = false;
    this.cycleEventDate = null;
    this.startOf = null;
    this.startOfSubClass = null;
    this.endOf = null;
    this.endOfSubClass = null;

    this.chartData = {
      datasets: this.getDataSetsForParcel()
    };
  }

  public onChangeLayer(layer: Layers): void {
    this.selectedLayer = layer;
    this.changeLayer();
  }

  public changeLayer(): void {
    let mapLayerToLoad = MapLayersCodeLayersMapping[this.selectedLayer];

    if (this.selectedLayer === Layers.LANDSAT_RGB) {
      mapLayerToLoad = LandSatRGB[this.landsatTypeOnDate];
    }

    if (this.selectedLayer === Layers.LANDSAT_NDVI) {
      mapLayerToLoad = LandSatNDVI[this.landsatTypeOnDate];
    }

    if (mapLayerToLoad) {
      this.showMapLayer(mapLayerToLoad);
      this.showCloudMask(this.cloudMaskSelected);
    } else {
      this.map.removeLayer(this.basemapLayerId);
      this.map.removeSource(this.basemapSourceId);
    }
  }

  public onShowCloudMask(show: boolean): void {
    this.cloudMaskSelected = show;
    this.showCloudMask(show);
  }

  showCloudMask(show: boolean): void {
    this.cloudMaskSelected = show;
    if (this.map.getLayer(this.cloudmaskLayerId)) {
      this.map.removeLayer(this.cloudmaskLayerId);
    }
    if (this.map.getSource(this.cloudmaskSourceId)) {
      this.map.removeSource(this.cloudmaskSourceId);
    }
    if (show) {
      let cloudMask = CloudMaskNameSatelliteMapping[SatelliteLayerMapping[this.selectedLayer]];

      if (cloudMask !== CloudMaskLayer.SENTINEL) {
        cloudMask = AnalyticSatelliteCloudMaskMapping[this.landsatTypeOnDate] ?? cloudMask;
      }
      this.addBaseMapLayer(cloudMask, this.cloudmaskLayerId, this.cloudmaskSourceId);
      this.updateCloudTransparency(this.transparency);
    }
  }

  toggleHarvest(): void {
    if (this.isHarvestVisible) {
      this.showHarvest();
    } else {
      this.hideHarvest();
    }
  }

  showHarvest(): void {
    this.map.setLayoutProperty(this.harvestLayerId, 'visibility', 'visible');
  }

  hideHarvest(): void {
    this.map.setLayoutProperty(this.harvestLayerId, 'visibility', 'none');
  }

  hideLandUse(): void {
    this.map.setLayoutProperty(this.landUseLayerId, 'visibility', 'none');
  }

  public onUpdateCloudTransparency(value: number): void {
    this.transparency = value;
    this.updateCloudTransparency(value);
  }

  private updateCloudTransparency(value: number) {
    this.map.setPaintProperty(this.cloudmaskLayerId, 'raster-opacity', (100 - value) / 100);
  }

  updateLabelsTransparency(value: number) {
    this.map.setPaintProperty(this.smallSquaresLayerId, 'line-opacity', (100 - value) / 100);
  }

  private showMapLayer(layerName): void {
    this.addBaseMapLayer(layerName, this.basemapLayerId, this.basemapSourceId);
  }

  private addBaseMapLayer(layerName, layerId, sourceId): void {
    if (this.map.getLayer(layerId)) {
      this.map.removeLayer(layerId);
    }
    if (this.map.getSource(sourceId)) {
      this.map.removeSource(sourceId);
    }
    let source: any = {
      type: 'raster',
      url: 'mapbox://mapbox.satellite'
    };
    if (layerName !== this.LAYERS.SATELLITE) {
      const subUrl = LayersURLMapping[layerName];
      if (this.parcel && this.parcel.Shape) {
        source = {
          type: 'raster',
          tiles: [
            `https://${subUrl}.sentinel-hub.com/ogc/wms/${process.env.VUE_APP_SENTINEL_TOKEN_SAT_EXPLORER}?showLogo=false&service=WMS&request=GetMap&layers=${layerName},DATE&styles=&format=image%2Fjpeg&transparent=false&version=1.1.1&maxcc=100&time=${this.selectedDate}&height=256&width=256&srs=EPSG%3A3857&bbox={bbox-epsg-3857}&zoom={z}`
          ],
          maxzoom: 16,
          minzoom: 13,
          tileSize: 256
        };
        if (this.isMimizeSHubUsage) {
          source.maxzoom = 15;
          source.minzoom = 15;

          source.bounds = turfBbox(buffer(this.parcel.Shape, 0.05, { units: 'kilometers' }));
        }
      }
    }
    this.map.addSource(sourceId, source);
    this.map.addLayer(
      {
        id: layerId,
        type: 'raster',
        paint: {
          'raster-resampling': 'nearest'
        },
        source: sourceId
      },
      this.parcelLayerId
    );
  }

  stopDrawingMode(): void {
    this.draw.changeMode('simple_select');
    this.selectedLabellingClass = null;
    this.drawSelectedFeatureIds = null;
    this.map.getCanvas().style.cursor = 'unset';
  }

  private hasIntersectionWithParcel(f: Feature<Polygon>): boolean {
    const intersection = polygonClipping.intersection(
      f.geometry.coordinates as Ring[],
      this.parcel.Shape.features[0].geometry.coordinates as Ring[]
    );
    return !!intersection && intersection.length > 0;
  }

  private getSquaresForLabelling(): any[] {
    if (!this.isEditable) {
      return [];
    }

    const squareFeatures = [];
    const polygonTiles = tiles(this.parcel.Shape.features[0].geometry, {
      min_zoom: this.labelledData.zoom,
      max_zoom: this.labelledData.zoom
    });
    polygonTiles.forEach((tile) => {
      const key = tile[0] + '_' + tile[1];
      const label = this.labelledData.grid[key];
      const featureParams = { key };

      featureParams['color'] = LabelClassTypeUtil.getLabelClassColor(undefined); // default value
      if (label) {
        featureParams[label] = true;
        featureParams['color'] = LabelClassTypeUtil.getLabelClassColor(label as LabelClassType);
      }

      const smallFeature = feature(tileToGeoJSON(tile), {
        ...featureParams
      });
      squareFeatures.push(smallFeature);
    });
    return squareFeatures;
  }

  private bboxIntersect(bbox1: BBox, bbox2: BBox): boolean {
    return !(bbox1[0] > bbox2[2] || bbox1[2] < bbox2[0] || bbox1[3] < bbox2[1] || bbox1[1] > bbox2[3]);
  }

  private markSelectedFeatures(polygon: Feature<Polygon>): void {
    if (polygon && this.labellingSquares) {
      let needUpdate = false;
      const polygonBbox = bbox(polygon);
      this.labellingSquares.forEach((feature: Feature<Polygon>) => {
        const featureBbox = bbox(feature);

        if (!this.bboxIntersect(polygonBbox, featureBbox)) {
          return;
        }

        let intersection = polygonClipping.intersection(
          polygon.geometry.coordinates as any,
          feature.geometry.coordinates as any
        );
        intersection = intersection || [];
        if (!intersection.length) {
          return;
        }

        const key = feature.properties.key; // x_y
        const featureProps = { key };

        featureProps[this.selectedLabellingClass] = true;
        featureProps['color'] = LabelClassTypeUtil.getLabelClassColor(this.selectedLabellingClass);
        this.labelledData.grid[key] = this.selectedLabellingClass;

        feature.properties = { ...featureProps };
        needUpdate = true;
      });
      if (needUpdate) {
        this.updateGeoJsonSource(this.smallSquaresSourceId, featureCollection(this.labellingSquares));
      }
    }
  }

  private deleteSelectedFeatures(polygon: Feature<Polygon>): void {
    if (polygon && this.labellingSquares) {
      let needUpdate = false;
      const polygonBbox = bbox(polygon);
      this.labellingSquares.forEach((feature: Feature<Polygon>) => {
        const featureBbox = bbox(feature);

        if (!this.bboxIntersect(polygonBbox, featureBbox)) {
          return;
        }

        let intersection = polygonClipping.intersection(
          polygon.geometry.coordinates as any,
          feature.geometry.coordinates as any
        );
        intersection = intersection || [];
        if (!intersection.length) {
          return;
        }

        const key = feature.properties.key; // x_y

        feature.properties.color = LabelClassTypeUtil.getLabelClassColor(undefined);
        delete feature.properties[this.selectedLabellingClass];
        // if tile has empty label, it will be removed on BE
        this.labelledData.grid[key] = '';

        needUpdate = true;
      });
      if (needUpdate) {
        this.updateGeoJsonSource(this.smallSquaresSourceId, featureCollection(this.labellingSquares));
      }
    }
  }

  private initClassPaint(labellClass: LabelClassType): void {
    this.selectedLabellingClass = labellClass;
    this.map.getCanvas().style.cursor = 'crosshair';
    this.draw.changeMode('paint', {
      color: LabelClassTypeUtil.getLabelClassColor(labellClass)
    });
  }

  paintLabelClass(labellClass: LabelClassType): void {
    if (this.selectedLabellingClass === labellClass) {
      return this.exitLabellingMode();
    }
    this.initClassPaint(labellClass);
  }

  exitLabellingMode(): void {
    this.draw.changeMode('simple_select');
    this.selectedLabellingClass = null;
    this.map.getCanvas().style.cursor = 'unset';
  }

  private initDeleteLabellPaint(): void {
    this.selectedLabellingClass = LabelClassType.UNKNOWN;
    this.draw.changeMode('paint', {
      color: '#000000'
    });
  }

  deleteLabelPaint(): void {
    if (this.selectedLabellingClass === LabelClassType.UNKNOWN) {
      return this.exitLabellingMode();
    }
    this.initDeleteLabellPaint();
  }

  private hideSquareGrid(): void {
    this.updateGeoJsonSource(this.smallSquaresSourceId, featureCollection([]));
  }

  get parcelBbox(): string {
    return `${this.parcel.LLLong},${this.parcel.LLLat},${this.parcel.URLong},${this.parcel.URLat}`;
  }

  async loadLabellingData() {
    this.$store.dispatch('setIsGlobalLoaderVisible', true);
    try {
      await Promise.all([this.fetchLabellingTiles(), this.fetchNotes()]);
    } catch (e: any) {
      message.error(e?.error ?? e?.message ?? 'Failed to load data');
    }
    this.$store.dispatch('setIsGlobalLoaderVisible', false);
  }

  async fetchLabels() {
    const labels = await API.getLabellingParcelInfo({
      parcelId: this.parcelId
    });
    this.parcelLabels = labels;
  }

  async fetchNotes() {
    const labels = await API.getLabellingParcelInfoByDate({
      parcelId: this.parcelId,
      date: this.selectedDate
    });

    let notes = '';
    if (labels && labels.length > 0) {
      notes = labels.find((note: LabelParcelInfo) => note?.date === this.selectedDate)?.notes || notes;
    }
    this.notes = notes;
  }

  private async fetchLabellingTiles(): Promise<void> {
    const tiles = await API.getLabellingTiles({
      bbox: this.parcelBbox,
      date: this.selectedDate
    });

    this.labelledData.grid = {};
    tiles.forEach((tile) => {
      this.labelledData.grid[`${tile.x}_${tile.y}`] = tile.label;
    });

    this.labellingSquares = this.getSquaresForLabelling();
    this.updateGeoJsonSource(this.smallSquaresSourceId, featureCollection(this.labellingSquares));
  }

  async save(): Promise<void> {
    this.$store.dispatch('setIsGlobalLoaderVisible', true);

    const tilesToSave: LabelTile[] = Object.keys(this.labelledData.grid).map<LabelTile>((key) => {
      const [x, y] = key.split('_');
      const tile: LabelTile = {
        x: Number(x),
        y: Number(y),
        date: this.selectedDate,
        label: this.labelledData.grid[key]
      };
      const tilePolygon = feature(tileToGeoJSON([tile.x, tile.y, this.labelledData.zoom]));
      if (this.hasIntersectionWithParcel(tilePolygon)) {
        tile.parcelId = this.parcelId;
      }
      return tile;
    });

    try {
      const calls = [];
      if (tilesToSave.length) {
        calls.push(API.updateLabellingTiles(tilesToSave));
      }

      calls.push(
        API.updateLabellingNotes({
          parcelId: this.parcelId,
          notes: this.notes,
          date: this.selectedDate
        })
      );

      await Promise.all(calls);

      // update chart with new labels
      await this.fetchLabels();
      this.redrawChart();
    } finally {
      this.$store.dispatch('setIsGlobalLoaderVisible', false);
    }
  }

  private createHarvestLayer(): void {
    this.map.addSource(this.harvestSourceId, {
      type: 'geojson',
      data: featureCollection([])
    });

    this.map.addLayer({
      type: 'circle',
      id: this.harvestLayerId,
      source: this.harvestSourceId,
      paint: { 'circle-color': ['case', ['==', ['get', 'harvested'], true], 'white', 'green'], 'circle-radius': 5 }
    });
  }
  private createLandUseLayer(): void {
    this.map.addSource(this.landUseSourceId, {
      type: 'geojson',
      data: featureCollection([])
    });

    this.map.addLayer({
      type: 'circle',
      id: this.landUseLayerId,
      source: this.landUseSourceId,
      paint: {
        'circle-color': [
          'case',
          ['==', ['get', 'class'], 0],
          Constants.landUseColorMap[0],
          ['==', ['get', 'class'], 1],
          Constants.landUseColorMap[1],
          ['==', ['get', 'class'], 2],
          Constants.landUseColorMap[2],
          ['==', ['get', 'class'], 3],
          Constants.landUseColorMap[3],
          ['==', ['get', 'class'], 4],
          Constants.landUseColorMap[4],
          ['==', ['get', 'class'], 5],
          Constants.landUseColorMap[5],
          ['==', ['get', 'class'], 6],
          Constants.landUseColorMap[6],
          Constants.landUseColorMap[0]
        ],
        'circle-radius': 5
      }
    });
    this.map.on('mouseenter', this.landUseLayerId, (e) => {
      this.map.getCanvas().style.cursor = 'pointer';
      const cls = e.features[0].properties.class;
      this.popup.setLngLat(e.lngLat).setHTML(Constants.landUseClasses[cls]).addTo(this.map);
    });
    this.map.on('mouseleave', this.landUseLayerId, () => {
      this.map.getCanvas().style.cursor = '';
      this.popup.remove();
    });
  }
  showParcelInfo(): void {
    this.isParcelInfoVisible = true;
  }
  showParcelInput(): void {
    this.isParcelInputVisible = true;
  }
  useGeojson(): void {
    this.isParcelInputVisible = false;
    this.onLoad();
  }
}
