import modernizr from 'modernizr';

export function checkSupported(): boolean {
  const featuresSupported =
    modernizr.canvas &&
    modernizr.csscalc &&
    modernizr.cssgrid &&
    modernizr.flexbox &&
    modernizr.promises &&
    modernizr.es7array &&
    modernizr.webgl;

  let versionSupported = true;
  const userAgent = window.navigator.userAgent;
  if (userAgent.indexOf('MSIE ') > 0 || !!userAgent.match(/Trident.*rv:11\./)) {
    versionSupported = false;
  }

  return featuresSupported && versionSupported;
}
