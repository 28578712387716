export enum ProductGroupName {
  FIELD_INFO = 'field_info',
  RGB = 'RGB',
  PLANTING_GAPS = 'sowing',
  PRECISION_HARVEST_LINES = 'precision_harvest_lines',
  WEEDS = 'weeds',
  WEEDS_GRID = 'weeds_grid',
  SAT_WEEDS = 'sat_weeds',
  NEMATODES = 'nematodes',
  SUGAR_CONTENT_PREDICTION = 'sat-sugarcontent',
  SUGAR_CONTENT_YIELD = 'sugaryield',
  VIGOR_INDEX = 'VIGOR_INDEX',
  WEEDS_CLASSES = 'weeds_classes',
  SOIL_EROSION = 'soil_erosion',
  GROWTH_MONITORING = 'GROWTH_MONITORING'
}
