export enum SatelliteType {
  LANDSAT_7 = 'landsat7',
  LANDSAT_8 = 'landsat8',
  SENTINEL = 'sentinel'
}

export enum AnalyticSatelliteType {
  LANDSAT_7 = 'LS7',
  LANDSAT_8 = 'LS8'
}
