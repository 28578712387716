import { ProductType } from '@/enums/productType';
import { AnalyticType } from '@/enums/analyticType';
import { LayerName } from '@/enums/layerName';
import { LayerGroupName } from '@/enums/layerGroupName';
import { ProductGroupName } from '@/enums/productGroupName';
import { ProductLayersConfiguration } from '@/interfaces/productLayersConfiguration';
import { SurveySource } from '@/enums/surveySource';
import { AnalyticSatelliteType, SatelliteType } from '../enums/satelliteType';
import { CloudMaskLayer } from '../enums/cloudMask';
import { URLSentinelTypes, URLTypeNames } from '../enums/urlSentinelTypes';
import { Layers, MapLayer } from '@/enums/layerTypes';

export enum FilteredDataType {
  Ndvi = 1,
  OutlierFiltered,
  Cloudy
}
export default class Constants {
  static DATE_FORMAT = 'YYYY-MM-DD';

  static plantingGapsColorMap = {
    line: '#03fc7b',
    gap: '#ff5964',
    default: '#000'
  };
  /* CLASS_CORN_SOY=1
  CLASS_FOREST=3
  CLASS_ORCHARD=4
  CLASS_PASTURE=5
  CLASS_SUGARCANE=2
  CLASS_UNKNOWN=0
  CLASS_WATER=6*/
  static landUseClasses = {
    0: 'Unknown',
    1: 'Crop',
    2: 'Crop',
    3: 'Forest',
    4: 'Orchard',
    5: 'Pasture',
    6: 'Water'
  };
  static landUseColorMap = {
    0: '#363636',
    1: '#ebb220',
    2: '#ebb220',
    3: '#006316',
    4: '#da70d6',
    5: '#92b34a',
    6: '#39839a'
  };
  static parallelismColorMap = {
    TOO_WIDE: '#0501ff',
    WIDE: '#adceff',
    TOO_NEAR: '#e31a1c',
    NEAR: '#ff8587'
  };

  static parallelismRanges = {
    TOO_WIDE: ' > 1.7m',
    WIDE: '1.7m - 1.6m',
    TOO_NEAR: ' < 1.3m',
    NEAR: '1.3m - 1.4m'
  };

  static nematodesNewColorMap = {
    low: '#fdc38d',
    medium: '#ec620f',
    high: '#7f2704'
  };

  static nematodesRecurrentColorMap = {
    low: '#b7d4ea',
    medium: '#3b8bc2',
    high: '#08306b'
  };

  static statusColorMap = {
    Fresh: '#63c258',
    Ratoon: '#f2e111'
  };

  static plantingDateColorMap = {
    0: '#bb4441',
    1: '#d13f29',
    2: '#e7600c',
    3: '#f79b0e',
    4: '#fcc910',
    5: '#f2e111',
    6: '#a1e329',
    7: '#63c258',
    8: '#309257',
    9: '#1d5734',
    10: '#117797',
    11: '#05397e'
  };

  static numberOfCyclesColorMap = {
    0: '#117797',
    1: '#46B32B',
    2: '#8CD029',
    3: '#D2ED27',
    4: '#FBE625',
    5: '#EFA222',
    6: '#E35D1F',
    7: '#D7191C'
  };

  static cropStageColorMap = {
    0: '#ae0204',
    1: '#e34721',
    2: '#ef7726',
    3: '#fba32c',
    4: '#fec12f',
    5: '#ffda30',
    6: '#fff331',
    7: '#d8fc2a',
    8: '#89f61b',
    9: '#3bef0b',
    10: '#03e20e',
    11: '#10c347',
    12: '#1d96a3'
  };

  static weedsGridIntervalColorMap = {
    0: '#549432',
    5: '#8ABE25',
    20: '#FBDE4F',
    50: '#E27F1D',
    100: '#D93A2A'
  };

  static weedsClassesColors = {
    GW: '#57ff39',
    BRLOW: '#cb8bff',
    CW: '#17ae00',
    BRHIW: '#7200d0',
    default: '#ebdb34'
  };

  static indiaCountryIds = ['5e4e9c5a72310000e6004b25', '5e7ebac48e117b6f00f5f502'];
  static syngentaOrgsIds = ['5e4e9da72b6800009f010eb3', '5e7eba778e117b6f00f5f44d'];

  static harvestedColor = '#FFFFFF';
  static partialHarvestColor = '#cbf2ff';
  static CLOUDY_ANALYTIC_VALUE = -1;
  static TOO_YOUNG_ANALYTIC_VALUE = -2;
  static NO_CLUSTERING_ANALYTIC_VALUE = -3;
  static SENTINEL_1 = 'S1';
  static IS_CLOUDY = -1;

  static farmsColorMap = [];

  static varietiesColorMap = {};

  static productAnalyticMap = {
    [ProductType.RGB]: AnalyticType.RGB,
    [ProductType.WEEDS]: AnalyticType.WEEDS,
    [ProductType.WEEDS_GRID]: AnalyticType.WEEDS,
    [ProductType.WEEDS_CLASSES]: AnalyticType.WEEDS_CLASSES,
    [ProductType.SAT_WEEDS]: AnalyticType.SAT_WEEDS,
    [ProductType.PLANTING_GAPS]: AnalyticType.PLANTING_GAPS,
    [ProductType.PARCEL_NDVI]: AnalyticType.NDVI,
    [ProductType.NEMATODES]: AnalyticType.NEMATODES,
    [ProductType.PRECISION_HARVEST_LINES]: AnalyticType.PRECISION_HARVEST_LINES,
    [ProductType.SUGAR_CONTENT_PREDICTION]: AnalyticType.SUGAR_CONTENT_PREDICTION,
    [ProductType.SUGAR_CONTENT_YIELD]: AnalyticType.SUGAR_CONTENT_YIELD,
    [ProductType.VIGOR_INDEX]: AnalyticType.VIGOR_INDEX,
    [ProductType.SOIL_EROSION]: AnalyticType.SOIL_EROSION
  };

  static parcelLayersList: LayerName[] = [
    LayerName.BOUNDARIES,
    LayerName.FARM,
    LayerName.VARIETY,
    LayerName.STATUS,
    LayerName.PLANTING_DATE,
    LayerName.NUMBER_OF_CYCLES,
    LayerName.HARVESTED,
    LayerName.CROP_STAGE
  ];

  static defaultAnalyticLayerName = {
    [ProductType.WEEDS]: LayerName.WEEDS,
    [ProductType.WEEDS_GRID]: LayerName.WEEDS_GRID,
    [ProductType.SAT_WEEDS]: LayerName.WEEDS,
    [ProductType.PLANTING_GAPS]: LayerName.PLANTING_GAPS,
    [ProductType.PARCEL_NDVI]: LayerName.PARCEL_NDVI,
    [ProductType.NEMATODES]: LayerName.NEMATODES_2,
    [ProductType.PRECISION_HARVEST_LINES]: LayerName.PRECISION_HARVEST_LINES,
    [ProductType.SUGAR_CONTENT_PREDICTION]: LayerName.SUGAR_CONTENT_PREDICTION,
    [ProductType.SUGAR_CONTENT_YIELD]: LayerName.SUGAR_CONTENT_YIELD,
    [ProductType.VIGOR_INDEX]: LayerName.VIGOR_INDEX,
    [ProductType.WEEDS_CLASSES]: LayerName.WEEDS_CLASSES,
    [ProductType.SOIL_EROSION]: LayerName.SOIL_EROSION
  };

  static LOCAL_STORAGE_KEYS = {
    LANG: 'lang',
    SELECTED_COUNTRY_ID: 'countryId',
    SELECTED_ORGANIZATION_ID: 'organizationId',
    SELECTED_UNIT_ID: 'unitId',
    SELECTED_FARM_ID: 'farmId',
    SELECTED_PRODUCT: 'product'
  };

  static nematodesGroundtruthfields = {
    [LayerName.YIELD]: 'Yield bags/ha',
    [LayerName.NEMATODES_STRESS]: 'stress',
    [LayerName.TREATMENT]: 'treatment'
  };

  static defaultProductTabsRadar = [
    ProductGroupName.RGB,
    ProductGroupName.PLANTING_GAPS,
    ProductGroupName.WEEDS,
    ProductGroupName.WEEDS_GRID,
    ProductGroupName.SAT_WEEDS,
    ProductGroupName.PRECISION_HARVEST_LINES,
    ProductGroupName.GROWTH_MONITORING,
    ProductGroupName.SUGAR_CONTENT_YIELD,
    ProductGroupName.SUGAR_CONTENT_PREDICTION,
    ProductGroupName.NEMATODES,
    ProductGroupName.VIGOR_INDEX,
    ProductGroupName.WEEDS_CLASSES,
    ProductGroupName.SOIL_EROSION,
    ProductGroupName.FIELD_INFO
  ];

  static defaultLayersConfigurationRadar = [
    {
      name: LayerGroupName.ANALYTIC,
      layers: [LayerName.DRONE],
      overviewLayers: [LayerName.DRONE],
      allowedProductTypes: [ProductType.RGB],
      allowedProductGroup: ProductGroupName.RGB,
      isMultiple: true
    },
    {
      name: LayerGroupName.ANALYTIC,
      layers: [LayerName.WEEDS, LayerName.SSA_WEEDS, LayerName.DRONE],
      overviewLayers: [LayerName.WEEDS, LayerName.SSA_WEEDS],
      allowedProductTypes: [ProductType.WEEDS],
      allowedProductGroup: ProductGroupName.WEEDS,
      isMultiple: true
    },
    {
      name: LayerGroupName.ANALYTIC,
      layers: [LayerName.WEEDS_GRID, LayerName.SSA_WEEDS_GRID, LayerName.DRONE],
      overviewLayers: [LayerName.WEEDS_GRID, LayerName.SSA_WEEDS_GRID],
      allowedProductTypes: [ProductType.WEEDS_GRID],
      allowedProductGroup: ProductGroupName.WEEDS_GRID,
      isMultiple: true
    },
    {
      name: LayerGroupName.ANALYTIC,
      layers: [LayerName.WEEDS, LayerName.SSA_WEEDS],
      overviewLayers: [LayerName.WEEDS, LayerName.SSA_WEEDS],
      allowedProductTypes: [ProductType.SAT_WEEDS],
      allowedProductGroup: ProductGroupName.SAT_WEEDS,
      isMultiple: true
    },
    {
      name: LayerGroupName.ANALYTIC,
      layers: [LayerName.PLANTING_GAPS, LayerName.DRONE],
      overviewLayers: [LayerName.PLANTING_GAPS],
      allowedProductTypes: [ProductType.PLANTING_GAPS],
      allowedProductGroup: ProductGroupName.PLANTING_GAPS,
      isMultiple: true
    },
    {
      name: LayerGroupName.ANALYTIC,
      layers: [LayerName.PARCEL_NDVI],
      overviewLayers: [LayerName.PARCEL_NDVI],
      allowedProductTypes: [ProductType.SAT_ANOMALY, ProductType.PARCEL_NDVI],
      allowedProductGroup: ProductGroupName.GROWTH_MONITORING,
      mutuallyExclusive: [LayerName.PARCEL_NDVI],
      isMultiple: true
    },
    {
      name: LayerGroupName.ANALYTIC,
      layers: [LayerName.PARCEL_NDVI, LayerName.NEMATODES_1, LayerName.NEMATODES_2, LayerName.NEMATODES],
      overviewLayers: [LayerName.PARCEL_NDVI, LayerName.NEMATODES_1, LayerName.NEMATODES_2, LayerName.NEMATODES],
      allowedProductTypes: [ProductType.NEMATODES],
      allowedProductGroup: ProductGroupName.NEMATODES,
      mutuallyExclusive: [LayerName.PARCEL_NDVI, LayerName.NEMATODES_1, LayerName.NEMATODES_2, LayerName.NEMATODES],
      transparencySlider: true,
      isMultiple: true
    },
    {
      name: LayerGroupName.ANALYTIC,
      layers: [
        LayerName.PRECISION_HARVEST_LINES,
        LayerName.UNUTILIZED_AREAS,
        LayerName.POTENTIAL_TRAMPLING,
        LayerName.DRONE
      ],
      overviewLayers: [LayerName.PRECISION_HARVEST_LINES, LayerName.UNUTILIZED_AREAS, LayerName.POTENTIAL_TRAMPLING],
      mutuallyExclusive: [LayerName.UNUTILIZED_AREAS, LayerName.POTENTIAL_TRAMPLING],
      allowedProductTypes: [ProductType.PRECISION_HARVEST_LINES],
      allowedProductGroup: ProductGroupName.PRECISION_HARVEST_LINES,
      isMultiple: true
    },
    {
      name: LayerGroupName.ANALYTIC,
      layers: [LayerName.SUGAR_CONTENT_PREDICTION],
      overviewLayers: [LayerName.SUGAR_CONTENT_PREDICTION],
      allowedProductTypes: [ProductType.SUGAR_CONTENT_PREDICTION],
      allowedProductGroup: ProductGroupName.SUGAR_CONTENT_PREDICTION,
      isMultiple: true
    },
    {
      name: LayerGroupName.ANALYTIC,
      layers: [LayerName.SUGAR_CONTENT_YIELD],
      overviewLayers: [LayerName.SUGAR_CONTENT_YIELD],
      allowedProductTypes: [ProductType.SUGAR_CONTENT_YIELD],
      allowedProductGroup: ProductGroupName.SUGAR_CONTENT_YIELD,
      isMultiple: true
    },
    {
      name: LayerGroupName.ANALYTIC,
      layers: [LayerName.VIGOR_INDEX, LayerName.VIGOR_ZONE, LayerName.DRONE],
      overviewLayers: [LayerName.VIGOR_INDEX, LayerName.VIGOR_ZONE],
      allowedProductTypes: [ProductType.VIGOR_INDEX],
      allowedProductGroup: ProductGroupName.VIGOR_INDEX,
      isMultiple: true
    },
    {
      name: LayerGroupName.ANALYTIC,
      layers: [LayerName.SOIL_EROSION],
      overviewLayers: [LayerName.SOIL_EROSION],
      allowedProductTypes: [ProductType.SOIL_EROSION],
      allowedProductGroup: ProductGroupName.SOIL_EROSION,
      isMultiple: true
    },
    {
      name: LayerGroupName.GROUND_TRUTH,
      layers: [LayerName.NEMATODES_STRESS, LayerName.YIELD, LayerName.TREATMENT],
      allowedProductTypes: [ProductType.NEMATODES],
      allowedProductGroup: ProductGroupName.NEMATODES,
      isMultiple: true
    },
    {
      name: LayerGroupName.ANALYTIC,
      layers: [LayerName.WEEDS_CLASSES, LayerName.SSA_WEEDS_CLASSES, LayerName.DRONE],
      overviewLayers: [LayerName.WEEDS_CLASSES, LayerName.SSA_WEEDS_CLASSES],
      allowedProductTypes: [ProductType.WEEDS_CLASSES],
      allowedProductGroup: ProductGroupName.WEEDS_CLASSES,
      isMultiple: true
    },
    {
      name: LayerGroupName.FIELD_INFO,
      layers: Constants.parcelLayersList,
      allowedProductTypes: [ProductType.FIELD_INFO],
      allowedProductGroup: ProductGroupName.FIELD_INFO
    },
    {
      name: LayerGroupName.BASEMAP,
      layers: [
        LayerName.SATELLITE,
        LayerName.SATELLITE_PLACES,
        LayerName.SATELLITE_ROADS,
        LayerName.GOOGLE_SATELLITE,
        LayerName.OSM,
        LayerName.SENTINEL,
        LayerName.SENTINEL_DATE,
        LayerName.SENTINEL_NDVI,
        LayerName.SENTINEL_BARE_SOIL_MAP
      ],
      allowedProductTypes: [
        ProductType.RGB,
        ProductType.WEEDS,
        ProductType.WEEDS_GRID,
        ProductType.SAT_WEEDS,
        ProductType.PLANTING_GAPS,
        ProductType.PRECISION_HARVEST_LINES,
        ProductType.SAT_ANOMALY,
        ProductType.PARCEL_NDVI,
        ProductType.NEMATODES,
        ProductType.SUGAR_CONTENT_PREDICTION,
        ProductType.SUGAR_CONTENT_YIELD,
        ProductType.VIGOR_INDEX,
        ProductType.WEEDS_CLASSES,
        ProductType.SOIL_EROSION
      ]
    }
  ];

  static productSurveySource = {
    [ProductType.RGB]: SurveySource.DRONE,
    [ProductType.PLANTING_GAPS]: SurveySource.DRONE,
    [ProductType.PRECISION_HARVEST_LINES]: SurveySource.DRONE,
    [ProductType.WEEDS]: SurveySource.DRONE,
    [ProductType.WEEDS_GRID]: SurveySource.DRONE,
    [ProductType.WEEDS_CLASSES]: SurveySource.DRONE,
    [ProductType.VIGOR_INDEX]: SurveySource.DRONE,
    [ProductType.SAT_WEEDS]: SurveySource.SATELLITE,
    [ProductType.SOIL_EROSION]: SurveySource.SATELLITE,
    [ProductType.SUGAR_CONTENT_YIELD]: SurveySource.SATELLITE,
    [ProductType.SUGAR_CONTENT_PREDICTION]: SurveySource.SATELLITE,
    [ProductType.PARCEL_NDVI]: SurveySource.SATELLITE,
    [ProductType.NEMATODES]: SurveySource.SATELLITE,
    [ProductType.SAT_ANOMALY]: SurveySource.SATELLITE
  };

  static productGroupToAnalyticLayerGroup = {
    [ProductGroupName.FIELD_INFO]: LayerGroupName.FIELD_INFO,
    [ProductGroupName.RGB]: LayerGroupName.ANALYTIC,
    [ProductGroupName.PLANTING_GAPS]: LayerGroupName.ANALYTIC,
    [ProductGroupName.PRECISION_HARVEST_LINES]: LayerGroupName.ANALYTIC,
    [ProductGroupName.SAT_WEEDS]: LayerGroupName.ANALYTIC,
    [ProductGroupName.WEEDS]: LayerGroupName.ANALYTIC,
    [ProductGroupName.WEEDS_GRID]: LayerGroupName.ANALYTIC,
    [ProductGroupName.WEEDS_CLASSES]: LayerGroupName.ANALYTIC,
    [ProductGroupName.SOIL_EROSION]: LayerGroupName.ANALYTIC,
    [ProductGroupName.VIGOR_INDEX]: LayerGroupName.ANALYTIC,
    [ProductGroupName.SUGAR_CONTENT_YIELD]: LayerGroupName.ANALYTIC,
    [ProductGroupName.SUGAR_CONTENT_PREDICTION]: LayerGroupName.ANALYTIC,
    [ProductGroupName.NEMATODES]: LayerGroupName.ANALYTIC,
    [ProductGroupName.GROWTH_MONITORING]: LayerGroupName.ANALYTIC
  };

  static defaultProductGroupAnalyticLayersConfiguration: ProductLayersConfiguration = {
    [ProductGroupName.FIELD_INFO]: Constants.parcelLayersList.map((layer: LayerName) => {
      return {
        name: ProductType.FIELD_INFO,
        defaultLayer: layer,
        isLayersVisible: false,
        isHeaderVisible: true,
        layers: [
          {
            name: layer
          }
        ],
        backgroundLayers: []
      };
    }),
    [ProductGroupName.RGB]: [
      {
        name: ProductType.RGB,
        defaultLayer: LayerName.DRONE,
        isLayersVisible: true,
        layers: [
          {
            name: LayerName.DRONE
          }
        ],
        backgroundLayers: []
      }
    ],
    [ProductGroupName.NEMATODES]: [
      {
        name: ProductType.PARCEL_NDVI,
        defaultLayer: LayerName.PARCEL_NDVI,
        isHeaderVisible: true,
        isLayersVisible: true,
        layers: [
          {
            name: LayerName.PARCEL_NDVI,
            onlyForSingleSelectedParcel: true,
            separator: true
          }
        ],
        backgroundLayers: []
      },
      {
        name: ProductType.NEMATODES,
        defaultLayer: LayerName.NEMATODES_2,
        isLayersVisible: true,
        isHeaderVisible: true,
        layers: [
          {
            name: LayerName.NEMATODES_1,
            onlyForSingleSelectedParcel: true,
            separator: true
          },
          {
            name: LayerName.NEMATODES_2,
            onlyForSingleSelectedParcel: true
          },
          {
            name: LayerName.NEMATODES,
            onlyForSingleSelectedParcel: true
          }
        ],
        mutuallyExclusive: [LayerName.NEMATODES_1, LayerName.NEMATODES_2, LayerName.NEMATODES],
        isRadioButtons: true,
        backgroundLayers: []
      }
    ],
    [ProductGroupName.SUGAR_CONTENT_YIELD]: [
      {
        name: ProductType.SUGAR_CONTENT_YIELD,
        defaultLayer: LayerName.SUGAR_CONTENT_YIELD,
        isLayersVisible: true,
        layers: [
          {
            name: LayerName.SUGAR_CONTENT_YIELD
          }
        ],
        backgroundLayers: []
      }
    ],
    [ProductGroupName.SUGAR_CONTENT_PREDICTION]: [
      {
        name: ProductType.SUGAR_CONTENT_PREDICTION,
        defaultLayer: LayerName.SUGAR_CONTENT_PREDICTION,
        isLayersVisible: true,
        layers: [
          {
            name: LayerName.SUGAR_CONTENT_PREDICTION
          }
        ],
        backgroundLayers: []
      }
    ],
    [ProductGroupName.SOIL_EROSION]: [
      {
        name: ProductType.SOIL_EROSION,
        defaultLayer: LayerName.SOIL_EROSION,
        isLayersVisible: true,
        layers: [
          {
            name: LayerName.SOIL_EROSION
          }
        ],
        backgroundLayers: []
      }
    ],
    [ProductGroupName.PLANTING_GAPS]: [
      {
        name: ProductType.PLANTING_GAPS,
        defaultLayer: LayerName.PLANTING_GAPS,
        isLayersVisible: true,
        layers: [
          {
            name: LayerName.PLANTING_GAPS
          },
          {
            name: LayerName.DRONE,
            separator: true
          }
        ],
        backgroundLayers: []
      }
    ],
    [ProductGroupName.VIGOR_INDEX]: [
      {
        name: ProductType.VIGOR_INDEX,
        defaultLayer: LayerName.VIGOR_INDEX,
        isLayersVisible: true,
        layers: [
          {
            name: LayerName.VIGOR_INDEX
          },
          {
            name: LayerName.VIGOR_ZONE
          },
          {
            name: LayerName.DRONE,
            separator: true
          }
        ],
        backgroundLayers: []
      }
    ],
    [ProductGroupName.PRECISION_HARVEST_LINES]: [
      {
        name: ProductType.PRECISION_HARVEST_LINES,
        defaultLayer: LayerName.PRECISION_HARVEST_LINES,
        isLayersVisible: true,
        layers: [
          {
            name: LayerName.PRECISION_HARVEST_LINES
          },
          {
            name: LayerName.UNUTILIZED_AREAS
          },
          {
            name: LayerName.POTENTIAL_TRAMPLING
          },
          {
            name: LayerName.DRONE,
            separator: true
          }
        ],
        mutuallyExclusive: [LayerName.UNUTILIZED_AREAS, LayerName.POTENTIAL_TRAMPLING],
        backgroundLayers: []
      }
    ],
    [ProductGroupName.SAT_WEEDS]: [
      {
        name: ProductType.SAT_WEEDS,
        defaultLayer: LayerName.WEEDS,
        isLayersVisible: true,
        layers: [
          {
            name: LayerName.WEEDS
          },
          {
            name: LayerName.SSA_WEEDS
          }
        ],
        backgroundLayers: []
      }
    ],
    [ProductGroupName.WEEDS]: [
      {
        name: ProductType.WEEDS,
        defaultLayer: LayerName.WEEDS,
        isLayersVisible: true,
        layers: [
          {
            name: LayerName.WEEDS
          },
          {
            name: LayerName.SSA_WEEDS
          },
          {
            name: LayerName.DRONE,
            separator: true
          }
        ],
        backgroundLayers: []
      }
    ],
    [ProductGroupName.WEEDS_GRID]: [
      {
        name: ProductType.WEEDS_GRID,
        defaultLayer: LayerName.WEEDS_GRID,
        isLayersVisible: true,
        layers: [
          {
            name: LayerName.WEEDS_GRID
          },
          {
            name: LayerName.SSA_WEEDS_GRID
          },
          {
            name: LayerName.DRONE,
            separator: true
          }
        ],
        mutuallyExclusive: [LayerName.WEEDS_GRID, LayerName.SSA_WEEDS_GRID],
        backgroundLayers: []
      }
    ],
    [ProductGroupName.WEEDS_CLASSES]: [
      {
        name: ProductType.WEEDS_CLASSES,
        defaultLayer: LayerName.WEEDS_CLASSES,
        isLayersVisible: true,
        layers: [
          {
            name: LayerName.WEEDS_CLASSES
          },
          {
            name: LayerName.SSA_WEEDS_CLASSES
          },
          {
            name: LayerName.DRONE,
            separator: true
          }
        ],
        backgroundLayers: []
      }
    ],
    [ProductGroupName.GROWTH_MONITORING]: [
      {
        name: ProductType.PARCEL_NDVI,
        defaultLayer: LayerName.PARCEL_NDVI,
        isHeaderVisible: true,
        isLayersVisible: true,
        layers: [
          {
            name: LayerName.PARCEL_NDVI,
            onlyForSingleSelectedParcel: true,
            separator: true
          }
        ],
        backgroundLayers: []
      }
    ]
  };
}

export enum LabelClassType {
  ANOMALY_FIRE = 'anomaly-fire',
  ANOMALY_FROST = 'anomaly-frost',
  ANOMALY_OTHER = 'anomaly-other',
  CLOUD = 'cloud',
  HARVERST = 'harvest',
  NORMAL = 'normal',
  FOREST = 'forest',
  DEFORESTATION = 'deforestation',
  UNKNOWN = 'unknown'
}

export class LabelClassTypeUtil {
  public static getLabelClassColor(labelClass: LabelClassType | undefined): string {
    const labelClassColors = {
      [LabelClassType.ANOMALY_FIRE]: '#FF0000',
      [LabelClassType.ANOMALY_FROST]: '#0000ff',
      [LabelClassType.ANOMALY_OTHER]: '#ff33cc',
      [LabelClassType.HARVERST]: '#996600',
      [LabelClassType.CLOUD]: '#aaaaaa',
      [LabelClassType.NORMAL]: '#1A6F00',
      [LabelClassType.UNKNOWN]: '#000000',
      [LabelClassType.FOREST]: '#17EBAC',
      [LabelClassType.DEFORESTATION]: '#FCAF3E',
      default: 'white'
    };

    return labelClassColors[labelClass] || labelClassColors['default'];
  }
}

export const LabelKeyMapping = {
  [LabelClassType.UNKNOWN]: '0',
  [LabelClassType.HARVERST]: '1',
  [LabelClassType.ANOMALY_FIRE]: '2',
  [LabelClassType.ANOMALY_FROST]: '3',
  [LabelClassType.ANOMALY_OTHER]: '4',
  [LabelClassType.CLOUD]: '5',
  [LabelClassType.NORMAL]: '6',
  [LabelClassType.FOREST]: '7',
  [LabelClassType.DEFORESTATION]: '8'
};

export const LayerAnalyticMapping = {
  [Layers.SENTINEL_RGB]: AnalyticType.NDVI,
  [Layers.SENTINEL_NDVI]: AnalyticType.NDVI,
  [Layers.SENTINEL_RADAR]: AnalyticType.NDVI,
  [Layers.SATELLITE]: AnalyticType.NDVI,
  [Layers.SENTINEL_NDMI]: AnalyticType.NDVI,
  [Layers.SENTINEL_NDWI]: AnalyticType.NDVI,
  [Layers.SENTINEL_NDRE]: AnalyticType.NDVI,
  [Layers.SENTINEL_SAVI]: AnalyticType.NDVI,
  [Layers.LANDSAT_RGB]: AnalyticType.LANDSAT_NDVI,
  [Layers.LANDSAT_NDVI]: AnalyticType.LANDSAT_NDVI,
  [Layers.LAND_USE]: AnalyticType.LAND_USE
};

export const SatelliteLayerMapping = {
  [Layers.SENTINEL_RGB]: SatelliteType.SENTINEL,
  [Layers.SENTINEL_NDVI]: SatelliteType.SENTINEL,
  [Layers.SENTINEL_RADAR]: SatelliteType.SENTINEL,
  [Layers.SATELLITE]: SatelliteType.SENTINEL,
  [Layers.SENTINEL_NDMI]: SatelliteType.SENTINEL,
  [Layers.SENTINEL_NDWI]: SatelliteType.SENTINEL,
  [Layers.SENTINEL_NDRE]: SatelliteType.SENTINEL,
  [Layers.SENTINEL_SAVI]: SatelliteType.SENTINEL,
  [Layers.LANDSAT_RGB]: SatelliteType.LANDSAT_8, // 8 is Default
  [Layers.LANDSAT_NDVI]: SatelliteType.LANDSAT_8 // 8 is Default
};

export const SatelliteURLSuffix = {
  [SatelliteType.LANDSAT_7]: URLTypeNames.LANDSAT_7,
  [SatelliteType.LANDSAT_8]: URLTypeNames.LANDSAT_8,
  [SatelliteType.SENTINEL]: URLTypeNames.SENTINEL
};

export const CloudMaskNameSatelliteMapping = {
  [SatelliteType.LANDSAT_7]: CloudMaskLayer.LANDSAT_7,
  [SatelliteType.LANDSAT_8]: CloudMaskLayer.LANDSAT_8,
  [SatelliteType.SENTINEL]: CloudMaskLayer.SENTINEL
};

export const AnalyticSatelliteCloudMaskMapping = {
  [AnalyticSatelliteType.LANDSAT_7]: CloudMaskLayer.LANDSAT_7,
  [AnalyticSatelliteType.LANDSAT_8]: CloudMaskLayer.LANDSAT_8
};

export const LayersURLMapping = {
  [MapLayer.SENTINEL_RGB]: URLSentinelTypes.DEFAULT,
  [MapLayer.SENTINEL_NDVI]: URLSentinelTypes.DEFAULT,
  [MapLayer.SENTINEL_RADAR]: URLSentinelTypes.DEFAULT,
  [MapLayer.SENTINEL_NDMI]: URLSentinelTypes.DEFAULT,
  [MapLayer.SENTINEL_NDWI]: URLSentinelTypes.DEFAULT,
  [MapLayer.SENTINEL_NDRE]: URLSentinelTypes.DEFAULT,
  [MapLayer.SENTINEL_SAVI]: URLSentinelTypes.DEFAULT,
  [MapLayer.SATELLITE]: URLSentinelTypes.DEFAULT,
  [MapLayer.LANDSAT7_RGB]: URLSentinelTypes.US_WEST_2,
  [MapLayer.LANDSAT7_NDVI]: URLSentinelTypes.US_WEST_2,
  [MapLayer.LANDSAT8_RGB]: URLSentinelTypes.US_WEST_2,
  [MapLayer.LANDSAT8_NDVI]: URLSentinelTypes.US_WEST_2,
  [CloudMaskLayer.SENTINEL]: URLSentinelTypes.DEFAULT,
  [CloudMaskLayer.LANDSAT_7]: URLSentinelTypes.US_WEST_2,
  [CloudMaskLayer.LANDSAT_8]: URLSentinelTypes.US_WEST_2
};

// Mapping of layers name in code / layers name in sentinel hub.
export const MapLayersCodeLayersMapping = {
  [Layers.SENTINEL_RGB]: MapLayer.SENTINEL_RGB,
  [Layers.SENTINEL_NDVI]: MapLayer.SENTINEL_NDVI,
  [Layers.SENTINEL_RADAR]: MapLayer.SENTINEL_RADAR,
  [Layers.SENTINEL_NDMI]: MapLayer.SENTINEL_NDMI,
  [Layers.SENTINEL_NDWI]: MapLayer.SENTINEL_NDWI,
  [Layers.SENTINEL_NDRE]: MapLayer.SENTINEL_NDRE,
  [Layers.SENTINEL_SAVI]: MapLayer.SENTINEL_SAVI,
  [Layers.SATELLITE]: MapLayer.SATELLITE,
  [Layers.LANDSAT_RGB]: MapLayer.LANDSAT8_RGB, // 8 is Default
  [Layers.LANDSAT_NDVI]: MapLayer.LANDSAT8_NDVI // 8 is Default
};

export const LandSatRGB = {
  [AnalyticSatelliteType.LANDSAT_7]: MapLayer.LANDSAT7_RGB,
  [AnalyticSatelliteType.LANDSAT_8]: MapLayer.LANDSAT8_RGB
};

export const LandSatNDVI = {
  [AnalyticSatelliteType.LANDSAT_7]: MapLayer.LANDSAT7_NDVI,
  [AnalyticSatelliteType.LANDSAT_8]: MapLayer.LANDSAT8_NDVI
};
