import Axios, { AxiosError, CancelToken } from 'axios';
import { Parcel } from '@/interfaces/parcel';
import { ParcelsByLabelsResponse, ParcelsByLabelsQuery, ParcelSearchResults } from '@/interfaces/parcelsByLabels';
import { Farm } from '@/interfaces/farm';
import { Unit } from '@/interfaces/unit';
import { WeatherGrid } from '@/interfaces/weather';
import { Survey } from '@/interfaces/survey';
import { Country } from '@/interfaces/country';
import { UnitHierarchy } from '@/interfaces/unitHierarchy';
import { AnalyticData } from '@/interfaces/analyticData';
import { FeatureCollection } from '@turf/helpers';
import { AuthorizeResponse, RefreshSessionResponse } from '@/interfaces/auth';
import { AnalyticSummaryItem } from '@/interfaces/analyticSummaryItem';
import { SearchResult } from '@/interfaces/searchResult';
import { Organization } from '@/interfaces/organization';
import { AnalyticType } from '@/enums/analyticType';
import { UserInfoInput } from '@/interfaces/userInfo';
import Auth from '@/services/auth';
import { message } from 'ant-design-vue';
import { SurveySource } from '@/enums/surveySource';
import {
  ClassTypes,
  CycleEvent,
  LabellingParcelQuery,
  LabellingQuery,
  LabelParcelInfo,
  LabelTile
} from '@/interfaces/labelling';
import { CarShape } from '@/interfaces/car';

class API {
  static PRODUCT_MANAGER_URL = `${process.env.VUE_APP_APPSERVER}/api`;

  static SAT_HARVEST_BUCKET = process.env.VUE_APP_HARVEST_FOLDER;
  static LAND_CLASSIFICATION_RESULTS_BUCKET = process.env.VUE_APP_LAND_CLASSIFICATION_RESULTS_BUCKET;

  static CURATION_SERVICE_URL = `${process.env.VUE_APP_CURATION_SERVER}/api`;

  static getSurvey(surveyId: string): Promise<Survey> {
    return Axios.get(`${API.PRODUCT_MANAGER_URL}/survey?surveyId=${surveyId}`, {
      withCredentials: true
    })
      .then((result) => result.data)
      .catch(API.handleError);
  }

  static getUnitHierarchy(unitId: string): Promise<UnitHierarchy> {
    return Axios.get(
      `${API.PRODUCT_MANAGER_URL}/hierarchy?unit=${unitId}&published=true&exclude_surveys=true&exclude_ndvi=true`,
      {
        withCredentials: true
      }
    )
      .then((result) => result.data)
      .catch(API.handleError);
  }

  static getUnitSummary(unitId: string, from: string, to: string): Promise<AnalyticSummaryItem[]> {
    return Axios.get(`${API.PRODUCT_MANAGER_URL}/unit-summary?unit=${unitId}&from=${from}&to=${to}`, {
      withCredentials: true
    })
      .then((result) => result.data)
      .catch(API.handleError);
  }

  static getFarmSummary(farmId: string, from: string, to: string): Promise<AnalyticSummaryItem[]> {
    return Axios.get(`${API.PRODUCT_MANAGER_URL}/farm-summary?farm=${farmId}&from=${from}&to=${to}`, {
      withCredentials: true
    })
      .then((result) => result.data)
      .catch(API.handleError);
  }

  static getParcelSummary(parcelId: string, unitId: string, from: string, to: string): Promise<AnalyticSummaryItem[]> {
    return Axios.get(
      `${API.PRODUCT_MANAGER_URL}/parcel-summary?parcel=${parcelId}&unit=${unitId}&from=${from}&to=${to}`,
      {
        withCredentials: true
      }
    )
      .then((result) => result.data)
      .catch(API.handleError);
  }

  static getAnalyticData(
    unitId: string,
    from: string,
    to: string,
    source: SurveySource,
    loadUnpublished = false
  ): Promise<AnalyticData[]> {
    const url = `${API.PRODUCT_MANAGER_URL}/analytic-data2?unit=${unitId}&from=${from}&to=${to}&source=${source}${
      loadUnpublished ? '' : '&published=true'
    }`;
    return Axios.get(url, {
      withCredentials: true
    })
      .then(({ data }) => data || [])
      .catch(API.handleError);
  }

  static getAnalyticDataForParcel(
    parcelId: string,
    analyticType: AnalyticType,
    loadUnpublished = false
  ): Promise<AnalyticData[]> {
    const url = `${API.PRODUCT_MANAGER_URL}/analytic-data-parcel?parcelId=${parcelId}&analyticType=${analyticType}${
      loadUnpublished ? '' : '&published=true'
    }`;
    return Axios.get(url, {
      withCredentials: true
    })
      .then(({ data }) => data || [])
      .catch(API.handleError);
  }

  static getAnalyticFullData(
    unitId: string,
    from: string,
    to: string,
    source: SurveySource,
    loadUnpublished = false,
    excludeNDVI = false
  ): Promise<AnalyticData[]> {
    const url = `${API.PRODUCT_MANAGER_URL}/analytic-data?unit=${unitId}&from=${from}&to=${to}&source=${source}${
      loadUnpublished ? '' : '&published=true'
    }&${excludeNDVI ? '&exclude_ndvi=true' : ''}`;
    return Axios.get(url, {
      withCredentials: true
    })
      .then(({ data }) => data || [])
      .catch(API.handleError);
  }

  static getAnalyticDataFromStorage(fileName: string, source: string, dt: string): Promise<FeatureCollection> {
    return Axios.get(`https://storage.googleapis.com/${source}/${fileName}.json?v=${dt}`)
      .then((result) => result.data)
      .catch(() => null);
  }

  static getWeedsSsaAnalyticData(surveyId: string, source: string, dt: string): Promise<FeatureCollection> {
    const fileName = 'ssa';
    const sourceFolder = `${source}/${surveyId}`;
    return API.getAnalyticDataFromStorage(fileName, sourceFolder, dt);
  }

  static getWeedsSsa10AnalyticData(surveyId: string, source: string, dt: string): Promise<FeatureCollection> {
    const fileName = 'ssa_10';
    const sourceFolder = `${source}/${surveyId}`;
    return API.getAnalyticDataFromStorage(fileName, sourceFolder, dt);
  }

  static getWeedsAnalyticData(surveyId: string, source: string, dt: string): Promise<FeatureCollection> {
    let fileName = 'curated';
    let sourceFolder = `${source}/${surveyId}`;
    if (source === process.env.VUE_APP_SAT_WEEDS_FOLDER) {
      fileName = `${surveyId}`;
      sourceFolder = `${source}`;
    }
    return API.getAnalyticDataFromStorage(fileName, sourceFolder, dt);
  }

  static getWeedsGridAnalyticData(surveyId: string, source: string, dt: string): Promise<FeatureCollection> {
    let fileName = 'grid';
    let sourceFolder = `${source}/${surveyId}`;
    if (source === process.env.VUE_APP_SAT_WEEDS_FOLDER) {
      fileName = `${surveyId}`;
      sourceFolder = `${source}`;
    }
    return API.getAnalyticDataFromStorage(fileName, sourceFolder, dt);
  }

  static getHarvestAnalyticData(parcelId: string, date: string, version: number): Promise<FeatureCollection> {
    const fileName = `${parcelId}_${date}_pixels`;
    const sourceFolder = `${API.SAT_HARVEST_BUCKET}`;
    return API.getAnalyticDataFromStorage(fileName, sourceFolder, version.toString());
  }

  static getLandUseAnalyticData(parcelId: string, date: string, version: number): Promise<FeatureCollection> {
    const fileName = `${parcelId}_${date}`;
    const sourceFolder = `${API.LAND_CLASSIFICATION_RESULTS_BUCKET}`;
    return API.getAnalyticDataFromStorage(fileName, sourceFolder, version.toString());
  }

  static getUnitWeatherData(from: string, to: string, unitId: string): Promise<WeatherGrid[]> {
    return Axios.get(`${API.PRODUCT_MANAGER_URL}/weather?from=${from}&to=${to}&unit=${unitId}`, {
      withCredentials: true
    })
      .then((result) => result.data)
      .catch(API.handleError);
  }

  static getParcelWeatherData(from: string, to: string, lat: number, lon: number): Promise<WeatherGrid[]> {
    return Axios.get(`${API.PRODUCT_MANAGER_URL}/weather?from=${from}&to=${to}&lat=${lat}&lon=${lon}`, {
      withCredentials: true
    })
      .then((result) => result.data)
      .catch(API.handleError);
  }

  static publish(unitId: string, date: string, analytic: AnalyticType, isUnPublish = false): Promise<void> {
    return Axios.get(
      `${API.PRODUCT_MANAGER_URL}/publish?unit=${unitId}&date=${date}&analytic=${analytic}${
        isUnPublish ? '' : '&publish=true'
      }`,
      { withCredentials: true }
    )
      .then((result) => result.data)
      .catch(API.handleError);
  }
  static getCountries(): Promise<Country[]> {
    return Axios.get(`${API.PRODUCT_MANAGER_URL}/countries?valid=true`, { withCredentials: true })
      .then((result) => result.data)
      .catch(API.handleError);
  }

  static getCarShapes(unitId: string): Promise<CarShape> {
    return Axios.get(`${API.PRODUCT_MANAGER_URL}/car-shapes?unit=${unitId}`, { withCredentials: true })
      .then((result) => result.data)
      .catch(API.handleError);
  }

  static getParcel(parcelId: string, abort?: CancelToken): Promise<Parcel> {
    return Axios.get(`${API.PRODUCT_MANAGER_URL}/parcel?id=${parcelId}`, {
      withCredentials: true,
      cancelToken: abort
    })
      .then((result) => result.data)
      .catch(API.handleError);
  }
  static getParcelByNameId(nameId: string, abort?: CancelToken): Promise<ParcelSearchResults> {
    return Axios.get(`${API.PRODUCT_MANAGER_URL}/parcel-by-name-id?query=${nameId}`, {
      withCredentials: true,
      cancelToken: abort
    })
      .then((result) => result.data)
      .catch(API.handleError);
  }
  static getParcelsByLabels(query: ParcelsByLabelsQuery, abort?: CancelToken): Promise<ParcelsByLabelsResponse> {
    return Axios.post(`${API.CURATION_SERVICE_URL}/labelling/parcels-by-labels`, query, {
      withCredentials: true,
      cancelToken: abort
    })
      .then((result) => result.data)
      .catch(API.handleError);
  }

  static patchParcel(data: Partial<Parcel>): Promise<{ success: boolean; error: string }> {
    return Axios.patch(`${API.PRODUCT_MANAGER_URL}/parcel`, data, { withCredentials: true })
      .then((result) => result.data)
      .catch(API.handleError);
  }

  static getParcelAnalytics(
    parcelId: string,
    unitId: string,
    from: string,
    to: string,
    analytic: string
  ): Promise<Parcel> {
    return Axios.get(
      `${API.PRODUCT_MANAGER_URL}/parcel-analytics?parcel=${parcelId}&unit=${unitId}&analytic=${analytic}&from=${from}&to=${to}&include_raw_value=true`,
      {
        withCredentials: true
      }
    )
      .then((result) => result.data)
      .catch(API.handleError);
  }

  static getParcels(farmId: string): Promise<Parcel[]> {
    return Axios.get(`${API.PRODUCT_MANAGER_URL}/parcels?farm=${farmId}`, { withCredentials: true })
      .then((result) => result.data)
      .catch(API.handleError);
  }

  static getFarm(farmId: string): Promise<Farm> {
    return Axios.get(`${API.PRODUCT_MANAGER_URL}/farm?id=${farmId}`, { withCredentials: true })
      .then((result) => result.data)
      .catch(API.handleError);
  }

  static createFarm(UnitID: string, Name: string, Code: string): Promise<{ ID: string }> {
    return Axios.post(`${API.PRODUCT_MANAGER_URL}/farm`, { UnitID, Name, Code }, { withCredentials: true })
      .then((result) => result.data)
      .catch(API.handleError);
  }

  static getUnit(unitId: string): Promise<Unit> {
    return Axios.get(`${API.PRODUCT_MANAGER_URL}/unit?id=${unitId}`, { withCredentials: true })
      .then((result) => result.data)
      .catch(API.handleError);
  }

  static getClusterId(surveyId: string): Promise<string> {
    return Axios.get(`${API.PRODUCT_MANAGER_URL}/cluster-id-by-survey-id?surveyId=${surveyId}`, {
      withCredentials: true
    })
      .then((result) => (result.data && result.data.id ? result.data.id : null))
      .catch(API.handleError);
  }

  static getJson<T>(url: string): Promise<T> {
    return Axios.get(url, { withCredentials: true })
      .then((result) => result.data)
      .catch(API.handleError);
  }

  static authorize(): Promise<AuthorizeResponse> {
    return Axios.get(`${API.PRODUCT_MANAGER_URL}/authorize`, { withCredentials: true })
      .then((result) => result.data)
      .catch(API.handleError);
  }

  static logout(): Promise<void> {
    return Axios.get(`${API.PRODUCT_MANAGER_URL}/logout`, { withCredentials: true })
      .then((result) => result.data.success)
      .catch(API.handleError);
  }

  static changePassword(id: string, currentPassword: string, password: string): Promise<boolean> {
    return Axios.post(
      `${API.PRODUCT_MANAGER_URL}/change-user-password`,
      { id, password, currentPassword },
      { withCredentials: true }
    )
      .then((result) => result.data.success)
      .catch(API.handleError);
  }

  static changeUserInfo(userUpdateInfo: UserInfoInput): Promise<void> {
    return Axios.patch(`${API.PRODUCT_MANAGER_URL}/user`, userUpdateInfo, { withCredentials: true })
      .then((result) => result.data.success)
      .catch(API.handleError);
  }

  static saveGroundTruth(unitId: string, data: any): Promise<void> {
    return Axios.post(`${API.PRODUCT_MANAGER_URL}/save-ground-truth?unitID=${unitId}`, data, {
      withCredentials: true
    })
      .then((result) => result.data.success)
      .catch(API.handleError);
  }

  static search(key: string, unitId: string): Promise<SearchResult[]> {
    return Axios.get(`${API.PRODUCT_MANAGER_URL}/search?key=${key}&unit=${unitId}`, {
      withCredentials: true
    })
      .then((result) => result.data)
      .catch(API.handleError);
  }

  static getOrganizations(countryId: string): Promise<Organization[]> {
    return Axios.get(`${API.PRODUCT_MANAGER_URL}/organizations?country=${countryId}`, {
      withCredentials: true
    })
      .then((result) => result.data)
      .catch(API.handleError);
  }

  static getUnits(orgId: string): Promise<Unit[]> {
    return Axios.get(`${API.PRODUCT_MANAGER_URL}/units?org=${orgId}`, {
      withCredentials: true
    })
      .then((result) => result.data)
      .catch(API.handleError);
  }

  static getFarms(unitId: string): Promise<Farm[]> {
    return Axios.get(`${API.PRODUCT_MANAGER_URL}/farms?unit=${unitId}`, {
      withCredentials: true
    })
      .then((result) => result.data)
      .catch(API.handleError);
  }

  static refreshSession(): Promise<RefreshSessionResponse> {
    return Axios.get(`${API.PRODUCT_MANAGER_URL}/refresh-session`, { withCredentials: true })
      .then((result) => result.data)
      .catch(API.handleError);
  }

  static getNematodesReportCsv(surveyId: string): Promise<any> {
    return Axios.get(`https://storage.googleapis.com/${process.env.VUE_APP_SAT_NEMATODES_FOLDER}/${surveyId}.csv`)
      .then((result) => result.data)
      .catch(API.handleError);
  }

  static getLabellingParcelInfo(query: LabellingParcelQuery): Promise<LabelParcelInfo[]> {
    return Axios.get(`${API.CURATION_SERVICE_URL}/labelling/parcels/${query.parcelId}`, {
      withCredentials: true
    })
      .then((result) => result.data || [])
      .catch((err) => {
        if (err.response?.status !== 404) {
          return API.handleError(err);
        }
      });
  }

  static getLabellingParcelInfoByDate(query: LabellingParcelQuery): Promise<LabelParcelInfo[]> {
    return Axios.get(`${API.CURATION_SERVICE_URL}/labelling/parcels/${query.parcelId}/${query.date}`, {
      withCredentials: true
    })
      .then((result) => result.data || [])
      .catch((err) => {
        if (err.response?.status !== 404) {
          return API.handleError(err);
        }
      });
  }

  static getLabellingTiles(query: LabellingQuery): Promise<LabelTile[]> {
    return Axios.get(`${API.CURATION_SERVICE_URL}/labelling/tiles?date=${query.date}&bbox=${query.bbox}`, {
      withCredentials: true
    })
      .then((result) => result.data ?? [])
      .catch((err) => {
        if (err.response?.status !== 404) {
          return API.handleError(err);
        }
      });
  }

  static updateLabellingTiles(data: LabelTile[]): Promise<void> {
    return Axios.put(`${API.CURATION_SERVICE_URL}/labelling/tiles`, data, {
      withCredentials: true
    })
      .then((result) => result.data)
      .catch(API.handleError);
  }

  static updateLabellingNotes(data: Omit<LabelParcelInfo, 'labels'>): Promise<void> {
    return Axios.put(`${API.CURATION_SERVICE_URL}/labelling/parcels`, data, {
      withCredentials: true
    })
      .then((result) => result.data)
      .catch(API.handleError);
  }

  static getCropCycleEvents(parcelId: string): Promise<CycleEvent[]> {
    return Axios.get(`${API.CURATION_SERVICE_URL}/crop_cycles/parcel/${parcelId}`, {
      withCredentials: true
    })
      .then((result) => result.data)
      .catch(API.handleError);
  }

  static createOrUpdateCropCycleEvent(data: CycleEvent): Promise<void> {
    return Axios.post(`${API.CURATION_SERVICE_URL}/crop_cycles/parcel`, data, {
      withCredentials: true
    })
      .then((result) => result.data)
      .catch(API.handleError);
  }

  static deleteCropCycleEvent(parcelId: string, date: string): Promise<void> {
    return Axios.delete(`${API.CURATION_SERVICE_URL}/crop_cycles/parcel/${parcelId}/${date}`, {
      withCredentials: true
    })
      .then((result) => result.data)
      .catch(API.handleError);
  }

  static getClassTypes(): Promise<ClassTypes[]> {
    return Axios.get(`${API.CURATION_SERVICE_URL}/class_types`, {
      withCredentials: true
    })
      .then((result) => result.data)
      .catch(API.handleError);
  }

  static handleError(serviceError: AxiosError<any>): void {
    if (serviceError.response?.status === 403) {
      Auth.doLogin();
      return;
    }

    const errorMsg = serviceError.response?.data?.message ? serviceError.response.data.message : serviceError.message;
    message.error(errorMsg, 5);

    throw serviceError;
  }
}

export default API;
