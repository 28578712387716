export enum URLSentinelTypes {
  DEFAULT = 'services',
  US_WEST_2 = 'services-uswest2'
}

export enum URLTypeNames {
  LANDSAT_7 = 'DSS18',
  LANDSAT_8 = 'DSS13',
  SENTINEL = 'DSS2'
}
