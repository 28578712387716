
import { Component, Emit, Vue } from 'vue-property-decorator';
import API from '@/services/api';
import { Parcel } from '@/interfaces/parcel';
import { ParcelByLabelsResult, ParcelsByLabelsQuery } from '@/interfaces/parcelsByLabels';
import axios from 'axios';
import { LabelClassType, LabelClassTypeUtil } from '@/services/constants';

class LabelEvent {
  name: string;
  isSelected: boolean;
  colorCode: string;
  constructor(n: string, colorCode: string) {
    this.name = n;
    this.colorCode = colorCode;
    this.isSelected = false;
  }
}

@Component
export default class ParcelLabels extends Vue {
  txt = '';
  parcelId = '';
  selectedLabel?: LabelEvent;
  labels: Array<LabelEvent> = [];
  parcels: Array<ParcelByLabelsResult> = [];

  PageSize = 10;
  page = 1;
  parcelCount = 0;
  pixelCount = 0;
  processingTime = 0;

  isExpanded = false;
  searchAbort = axios.CancelToken.source();
  parcelsByLabelsAbort = axios.CancelToken.source();

  @Emit('selected:parcel')
  onSelectedParcel(parcel: string) {
    return parcel;
  }

  mounted() {
    this.page = 1;
    for (let key in LabelClassType) {
      if (LabelClassType[key] === LabelClassType.UNKNOWN) continue;
      this.labels.push(new LabelEvent(LabelClassType[key], LabelClassTypeUtil.getLabelClassColor(LabelClassType[key])));
    }
  }

  async toggleLabel(isSelected: boolean, label: LabelEvent) {
    label.isSelected = isSelected;
    this.page = 1;
    await this.load();
  }

  private async load() {
    this.parcelsByLabelsAbort.cancel('Parcels by labels triggered again.');
    this.parcelsByLabelsAbort = axios.CancelToken.source();

    let page = Math.max(0, this.page - 1);
    let labels = this.labels.filter((t) => t.isSelected).map((t) => t.name);
    if ((this.parcelId && this.parcelId.length) || labels.length) {
      let query = new ParcelsByLabelsQuery(this.parcelId, labels, page, this.PageSize);
      const result = await API.getParcelsByLabels(query, this.parcelsByLabelsAbort.token);
      this.parcelCount = result.parcelCount;
      this.pixelCount = result.pixelCount;
      this.parcels = result.results;
      this.processingTime = result.processingTime / 1000;
      // eslint-disable-next-line no-console
      console.log('Label-By-Parcel procesing time: ' + result.processingTime + 'msec');
    } else {
      this.pixelCount = 0;
      this.parcelCount = 0;
      this.parcels = [];
    }
  }
  public toggle() {
    this.isExpanded = !this.isExpanded;
  }

  async search() {
    await this.load();
    this.onSelectedParcel(this.parcelId);
  }

  async selectParcel(parcelResult: ParcelByLabelsResult) {
    this.onSelectedParcel(parcelResult.id);
  }

  async onPageChange(page: number) {
    this.page = page;
    await this.load();
  }
}
