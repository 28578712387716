import Vue from 'vue';
import VueRouter from 'vue-router';
import BaseTemplate from '../views/Base-Template.vue';
import { PageName } from '@/enums/pageName';
import UnsupportedBrowser from '@/views/UnsupportedBrowser.vue';
import HarvestBenchmark from '@/views/HarvestBenchmark.vue';
import Regen from '@/views/Regen.vue';

Vue.use(VueRouter);

const routes = [
  {
    path: '/',
    component: BaseTemplate,
    children: [
      {
        path: '',
        name: PageName.HARVEST_BENCHMARK,
        component: HarvestBenchmark
      },
      {
        path: '/regen',
        name: PageName.REGEN,
        component: Regen
      }
    ]
  },
  {
    path: '/unsupported',
    name: PageName.UNSUPPORTED_BROWSER,
    component: UnsupportedBrowser
  },
  { path: '*', redirect: '/' }
];

const router = new VueRouter({
  mode: 'history',
  routes
});

export default router;
