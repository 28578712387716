
import { Component, Vue } from 'vue-property-decorator';
import { checkSupported } from '@/services/unsupportedBrowser';
import { PageName } from '@/enums/pageName';

@Component
export default class UnsupportedBrowser extends Vue {
  mounted(): void {
    if (checkSupported()) {
      this.$router.push({ name: PageName.RADAR });
    }
  }
}
