
import { Component, Prop, Vue } from 'vue-property-decorator';
import { Line } from 'vue-chartjs/legacy';
import 'chartjs-adapter-moment';
import {
  Chart as ChartJS,
  Title,
  Tooltip,
  Legend,
  TimeScale,
  PointElement,
  LineElement,
  LinearScale,
  CategoryScale,
  BarElement,
  BarController,
  ScatterController
} from 'chart.js';

ChartJS.register(
  Title,
  Tooltip,
  Legend,
  TimeScale,
  PointElement,
  LineElement,
  LinearScale,
  CategoryScale,
  BarElement,
  BarController,
  ScatterController
);

@Component({
  components: { LineChartJs: Line }
})
export default class LineChart extends Vue {
  @Prop() chartData: any;
  @Prop() options: any;
  @Prop({
    default: () => {
      return {};
    }
  })
  styles: any;
  @Prop({ default: 400 }) width: number;
  @Prop({ default: 400 }) height: number;
  @Prop({ default: '' }) cssClasses: string;
  @Prop() chartId: string;

  public get chartJsId(): string {
    return this.chartId || `line-chart-${Math.random().toString(36).substr(2, 9)}`;
  }
}
