
import { Component, Vue, Watch } from 'vue-property-decorator';
import Mapbox from 'mapbox-gl-vue';
import API from '@/services/api';
import { Feature, featureCollection, FeatureCollection, Polygon } from '@turf/helpers';
import mapboxgl, { GeoJSONSource, LngLatBoundsLike } from 'mapbox-gl';
import { defaultSelectedParcelColor } from '@/services/analyticData';
import {
  SatelliteLayerMapping,
  SatelliteURLSuffix,
  CloudMaskNameSatelliteMapping,
  LayersURLMapping,
  MapLayersCodeLayersMapping,
  LandSatRGB,
  LandSatNDVI,
  AnalyticSatelliteCloudMaskMapping
} from '@/services/constants';
import Constants from '@/services/constants';
import { Unit } from '@/interfaces/unit';
import moment, { Moment } from 'moment';
import Axios from 'axios';
import turfBbox from '@turf/bbox';
import turfBuffer from '@turf/buffer';
import bboxPolygon from '@turf/bbox-polygon';
import { UserInfo } from '@/interfaces/userInfo';
import { Role } from '@/enums/role';
import router from '@/router';
import { PageName } from '@/enums/pageName';
import { message } from 'ant-design-vue';
import buffer from '@turf/buffer';
import { Layers } from '@/enums/layerTypes';
import { CarShape } from '@/interfaces/car';
import Timeline from '@/components/Timeline.vue';
import { getDatesInfoFromSelectedLayer } from '@/services/scenes';
import { SatelliteType } from '@/enums/satelliteType';
import { CloudMaskLayer } from '@/enums/cloudMask';
import DatePicker from '@/components/DatePicker.vue';
import LayerSelector from '@/components/LayerSelector.vue';

@Component({
  components: {
    Mapbox,
    Timeline,
    DatePicker,
    LayerSelector
  }
})
export default class Regen extends Vue {
  private basemapSourceId = 'basemapSourceId';
  private basemapLayerId = 'basemapLayerId';
  private cloudmaskSourceId = 'cloudmaskSourceId';
  private cloudmaskLayerId = 'cloudmaskLayerId';
  private carLayerId = 'carLayerId';
  private carSourceId = 'carSourceId';

  private DAYS_BACK = 365 * 7;
  private unitId = '';
  private carId = '';
  private isFocusDisabled = false;
  private isMimizeSHubUsage = true;
  private popup: mapboxgl.Popup = null;
  private map: mapboxgl.Map;
  private unit: Unit = null;
  private car: CarShape = null;
  private landSatDatesTypes: Record<string, string> = {};
  public landsatTypeOnDate = null;

  public LAYERS = Layers;
  public dates: string[] = [];
  public cloudCoverPercentageDateMap: { [key: string]: string } = {};
  public cloudMaskSelected = false;
  public transparency = 0; // %
  public selectedLayer = this.LAYERS.SENTINEL_RGB;
  public selectedDate = (this.$route.query.date as string) || moment.utc().format(Constants.DATE_FORMAT);

  @Watch('userInfo')
  onUserInfoChanged(): void {
    this.validateAccess();
  }

  @Watch('landsatTypeOnDate')
  onLandsatTypeOnDateChanged(): void {
    this.changeLayer();
  }

  private get userInfo(): UserInfo {
    return this.$store.state.userInfo;
  }

  private get carBbox(): string {
    const [minX, minY, maxX, maxY] = turfBbox(this.car.Shape);
    return `${minX},${minY},${maxX},${maxY}`;
  }

  private async mounted(): Promise<void> {
    this.validateAccess();
    await this.onLoad();
  }

  private async onLoad(): Promise<void> {
    try {
      this.$store.dispatch('setIsGlobalLoaderVisible', true);
      this.unitId = this.unitId || (this.$route.query.unitId as string);
      this.carId = this.carId || (this.$route.query.carId as string);

      if (this.unitId && this.carId) {
        const unitShape = await API.getCarShapes(this.unitId);

        const carShape = unitShape.Shape.features.find((feature) => feature.properties.id === this.carId);
        const carFeatureCollection = featureCollection([carShape]);

        this.car = {
          UnitID: this.unitId,
          Shape: carFeatureCollection,
          Properties: carShape.properties
        };
      }

      this.changeLayer();
      this.drawData();
      await this.updateDates(SatelliteLayerMapping[this.selectedLayer]);
    } catch (err) {
      message.error('Something went wrong: ' + err, 5);
    } finally {
      this.$store.dispatch('setIsGlobalLoaderVisible', false);
    }
  }

  private drawData(): void {
    if (!this.map) {
      return;
    }

    const carBbox = turfBbox(this.car.Shape);
    const bufferedBbox = turfBbox(turfBuffer(bboxPolygon(carBbox), 0.5)) as LngLatBoundsLike;
    if (!this.isFocusDisabled) {
      this.map.fitBounds(bufferedBbox, { duration: 0 });
    }

    this.drawParcel();
  }

  private drawParcel(): void {
    if (this.car && this.car.Shape) {
      this.updateGeoJsonSource(this.carSourceId, this.car.Shape);
    }
  }

  private validateAccess(): void {
    if (this.userInfo && !this.userInfo.Roles?.includes(Role.ADMIN)) {
      router.push(PageName.RADAR);
    }
  }

  async updateDates(satelliteType: string): Promise<void> {
    const { dates, cloudCoverPercentageDateMap, sourceDateMap } = await getDatesInfoFromSelectedLayer(
      this.DAYS_BACK,
      satelliteType,
      this.carBbox
    );

    if (satelliteType === SatelliteType.LANDSAT_7 || satelliteType === SatelliteType.LANDSAT_8) {
      this.landSatDatesTypes = sourceDateMap;
      this.setLandsatTypeOnDate();
    }

    this.cloudCoverPercentageDateMap = cloudCoverPercentageDateMap;

    this.dates = dates;
  }

  private setLandsatTypeOnDate(): void {
    this.landsatTypeOnDate = this.landSatDatesTypes[this.selectedDate];
  }

  public onMapLoaded(map: mapboxgl.Map): void {
    this.map = map;
    this.map.keyboard.disable();
    this.popup = new mapboxgl.Popup({ closeButton: false, closeOnClick: false });
    this.createCarLayer();
    this.changeLayer();
  }

  private createCarLayer(): void {
    this.map.addSource(this.carSourceId, {
      type: 'geojson',
      data: featureCollection([])
    });

    this.map.addLayer({
      id: this.carLayerId,
      type: 'line',
      source: this.carSourceId,
      paint: {
        'line-color': defaultSelectedParcelColor,
        'line-width': 3
      }
    });
  }

  public selectDate(date: string): void {
    this.selectedDate = date;
    this.onChangeDate();
  }

  private updateGeoJsonSource(sourceId: string, geoJsonData: FeatureCollection): void {
    const source = this.map.getSource(sourceId) as GeoJSONSource;
    if (source) {
      source.setData(geoJsonData as any);
    }
  }

  private onChangeDate(): void {
    this.changeLayer();
  }

  public onChangeLayer(layer: Layers): void {
    this.selectedLayer = layer;
    this.changeLayer();
  }

  public async changeLayer(): Promise<void> {
    let mapLayerToLoad = MapLayersCodeLayersMapping[this.selectedLayer];

    const isLandSatRGB = this.selectedLayer === Layers.LANDSAT_RGB;
    const isLandSatNDVI = this.selectedLayer === Layers.LANDSAT_NDVI;

    if (isLandSatRGB || isLandSatNDVI) {
      await this.updateDates(SatelliteLayerMapping[this.selectedLayer]);

      if (isLandSatRGB) {
        mapLayerToLoad = LandSatRGB[this.landsatTypeOnDate];
      }

      if (isLandSatNDVI) {
        mapLayerToLoad = LandSatNDVI[this.landsatTypeOnDate];
      }
    }

    if (mapLayerToLoad) {
      this.showMapLayer(mapLayerToLoad);
      this.showCloudMask(this.cloudMaskSelected);
    } else {
      this.map.removeLayer(this.basemapLayerId);
      this.map.removeSource(this.basemapSourceId);
    }
  }

  public onShowCloudMask(show: boolean): void {
    this.cloudMaskSelected = show;
    this.showCloudMask(show);
  }

  private showCloudMask(show: boolean): void {
    this.cloudMaskSelected = show;
    if (this.map.getLayer(this.cloudmaskLayerId)) {
      this.map.removeLayer(this.cloudmaskLayerId);
    }
    if (this.map.getSource(this.cloudmaskSourceId)) {
      this.map.removeSource(this.cloudmaskSourceId);
    }
    if (show) {
      let cloudMask = CloudMaskNameSatelliteMapping[SatelliteLayerMapping[this.selectedLayer]];

      if (cloudMask !== CloudMaskLayer.SENTINEL) {
        cloudMask = AnalyticSatelliteCloudMaskMapping[this.landsatTypeOnDate] ?? cloudMask;
      }
      this.addBaseMapLayer(cloudMask, this.cloudmaskLayerId, this.cloudmaskSourceId);
      this.updateCloudTransparency(this.transparency);
    }
  }

  public onUpdateCloudTransparency(value: number): void {
    this.transparency = value;
    this.updateCloudTransparency(value);
  }

  private updateCloudTransparency(value: number) {
    this.map.setPaintProperty(this.cloudmaskLayerId, 'raster-opacity', (100 - value) / 100);
  }

  private showMapLayer(layerName): void {
    this.addBaseMapLayer(layerName, this.basemapLayerId, this.basemapSourceId);
  }

  private addBaseMapLayer(layerName, layerId, sourceId): void {
    if (this.map.getLayer(layerId)) {
      this.map.removeLayer(layerId);
    }
    if (this.map.getSource(sourceId)) {
      this.map.removeSource(sourceId);
    }
    let source: any = {
      type: 'raster',
      url: 'mapbox://mapbox.satellite'
    };
    if (layerName !== this.LAYERS.SATELLITE) {
      const subUrl = LayersURLMapping[layerName];
      if (this.car && this.car.Shape) {
        source = {
          type: 'raster',
          tiles: [
            `https://${subUrl}.sentinel-hub.com/ogc/wms/${process.env.VUE_APP_SENTINEL_TOKEN_SAT_EXPLORER}?showLogo=false&service=WMS&request=GetMap&layers=${layerName},DATE&styles=&format=image%2Fjpeg&transparent=false&version=1.1.1&maxcc=100&time=${this.selectedDate}&height=256&width=256&srs=EPSG%3A3857&bbox={bbox-epsg-3857}&zoom={z}`
          ],
          maxzoom: 16,
          minzoom: 13,
          tileSize: 256
        };
        if (this.isMimizeSHubUsage) {
          source.maxzoom = 15;
          source.minzoom = 15;

          source.bounds = turfBbox(buffer(this.car.Shape, 0.05, { units: 'kilometers' }));
        }
      }
    }
    this.map.addSource(sourceId, source);
    this.map.addLayer(
      {
        id: layerId,
        type: 'raster',
        paint: {
          'raster-resampling': 'nearest'
        },
        source: sourceId
      },
      this.carLayerId
    );
  }

  // TODO: All following functions should be moved to a shared logic file:

  public getCurrentStyle(date: Moment): any {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const style: any = {};
    if (this.dates.includes(date.format(Constants.DATE_FORMAT))) {
      style.border = '1px solid #888';
      style.borderRadius = '50%';
    }
    return style;
  }

  public getCloudPercentStyle(date: Moment): any {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const style: any = {};
    const cloudPercentage = this.cloudCoverPercentageDateMap[date.format(Constants.DATE_FORMAT)];
    if (cloudPercentage !== undefined) {
      style.background = `linear-gradient(0deg, #ccc ${cloudPercentage}%, white ${cloudPercentage}%`;
      style.border = '1px solid #ddd';
    }
    return style;
  }

  private getFeaturesForRange(
    from: string,
    to: string,
    bbox: string,
    satelliteSuffix?: string
  ): Promise<Feature<Polygon>[]> {
    return Axios.get(
      `https://services.sentinel-hub.com/ogc/wfs/${process.env.VUE_APP_SENTINEL_TOKEN_SAT_EXPLORER}?REQUEST=GetFeature&OUTPUTFORMAT=application/json&VERSION=1.1.2&CRS=EPSG:4326&BBOX=${bbox}&TIME=${from}/${to}&MAXCC=100&TYPENAMES=${satelliteSuffix}`
    ).then((response) => response?.data?.features || []);
  }

  private getScenes(satelliteType: string): Promise<Feature<Polygon, { [name: string]: any }>[]> {
    const from = moment.utc().subtract(this.DAYS_BACK, 'days');
    const to = moment.utc();

    const ranges = [];
    while (from.isBefore(to)) {
      ranges.push({
        from: from.format(Constants.DATE_FORMAT),
        to: from.add(3, 'months').format(Constants.DATE_FORMAT)
      });
    }

    const bbox = this.carBbox;
    const tasks = ranges.map(({ from, to }) =>
      this.getFeaturesForRange(from, to, bbox, SatelliteURLSuffix[satelliteType])
    );

    return Promise.all(tasks).then((result) => {
      return result.flat();
    });
  }
}
