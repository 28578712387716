
import Constants from '@/services/constants';
import { stringToMomentDate } from '@/services/date';
import { Component, Prop, Vue, Watch } from 'vue-property-decorator';

@Component({})
export default class Timeline extends Vue {
  @Prop({ default: () => [] }) dates: string[];
  @Prop({ default: '' }) selectedDate: string;
  @Prop({ default: () => ({}) }) cloudCoverPercentageDateMap: Record<string, number>;
  @Prop({ default: () => ({}) }) parcelSurveysMap: Record<string, any>;

  @Watch('dates')
  @Watch('selectedDate')
  onSelectedDateChanged(): void {
    this.scrollToSelectedDate();
  }

  mounted(): void {
    document.onkeydown = (e) => {
      const target = e.target as any;
      const isInput = target?.tagName === 'INPUT' && target?.type === 'text';
      if (e.code === 'ArrowLeft' && !isInput) {
        e.preventDefault();
        this.gotoPrev();
      }
      if (e.code === 'ArrowRight' && !isInput) {
        e.preventDefault();
        this.gotoNext();
      }
    };
  }

  public gotoPrev(): void {
    if (this.selectedDate) {
      const selectedDateMoment = stringToMomentDate(this.selectedDate);
      let prevDateMoment = null;
      this.dates.forEach((date: string) => {
        const dateMoment = stringToMomentDate(date);
        if (dateMoment.isBefore(selectedDateMoment) && (!prevDateMoment || dateMoment.isAfter(prevDateMoment))) {
          prevDateMoment = dateMoment;
        }
      });
      if (prevDateMoment) {
        this.onChangeDate(prevDateMoment.format(Constants.DATE_FORMAT));
        this.scrollToSelectedDate();
      }
    }
  }

  public gotoNext(): void {
    if (this.selectedDate) {
      const selectedDateMoment = stringToMomentDate(this.selectedDate);
      let nextDateMoment = null;
      this.dates.forEach((date: string) => {
        const dateMoment = stringToMomentDate(date);
        if (dateMoment.isAfter(selectedDateMoment) && (!nextDateMoment || dateMoment.isBefore(nextDateMoment))) {
          nextDateMoment = dateMoment;
        }
      });
      if (nextDateMoment) {
        this.onChangeDate(nextDateMoment.format(Constants.DATE_FORMAT));
        this.scrollToSelectedDate();
      }
    }
  }

  private onChangeDate(date: string): void {
    this.$emit('on-change-date', date);
    this.scrollToSelectedDate();
  }

  private scrollToSelectedDate(): void {
    window.setTimeout(() => {
      const elements = document.getElementsByClassName('ant-radio-button-wrapper-checked');
      if (elements && elements.length) {
        elements[0].scrollIntoView({ behavior: 'smooth', inline: 'center' });
      }
    }, 500);
  }
}
