
import { Layers } from '@/enums/layerTypes';
import { SatelliteType } from '@/enums/satelliteType';
import { SatelliteLayerMapping } from '@/services/constants';
import { Component, Prop, Vue, Watch } from 'vue-property-decorator';

@Component({})
export default class LayerSelector extends Vue {
  @Prop({ default: () => ({}) }) cloudCoverPercentageDateMap: Record<string, number>;
  @Prop({ default: '' }) selectedDate: string;

  public LAYERS = Layers;
  public transparency = 0;
  public cloudMaskSelected = false;
  public selectedLayer = Layers.SENTINEL_RGB;

  public showCloudMask(show: boolean): void {
    this.$emit('on-show-cloud-mask', show);
  }

  public updateCloudTransparency(value: number): void {
    this.$emit('on-update-cloud-transparency', this.transparency);
  }

  public changeLayer(layer: Layers): void {
    this.$emit('on-change-layer', layer);
  }

  public get cloudMaskName(): string {
    if (SatelliteLayerMapping[this.selectedLayer] === SatelliteType.SENTINEL) {
      return 'Sentinel Cloud Mask';
    }
    return 'Landsat Cloud Mask';
  }
}
