import { Parcel } from './parcel';
export class ParcelsByLabelsQuery {
  parcelId: string;
  labels: string[];
  page: number;
  pageCount: number;
  constructor(parcelId: string, labels: string[], page: number, pageCount: number) {
    this.parcelId = parcelId;
    this.labels = labels;
    this.page = page;
    this.pageCount = pageCount;
  }
}

export class ParcelByLabelsResult {
  id: string;
  labels: string[];
  parcelId: string;
  eventCount: number;
}

export class ParcelsByLabelsResponse {
  results: ParcelByLabelsResult[];
  parcelCount: number;
  pixelCount: number;
  processingTime: number;
}

export class ParcelSearchResults {
  results: Parcel[];
  processingTime: number;
}
