export enum PageName {
  RADAR = 'radar',
  TABLE = 'table',
  PROFILE = 'profile',
  ADMIN = 'admin',
  HARVEST_BENCHMARK = 'harvest-benchmark',
  REGEN = 'regen',
  DASHBOARD = 'dashboard',
  UNSUPPORTED_BROWSER = 'unsupported'
}
