import { Feature, Polygon } from '@turf/helpers';
import Axios from 'axios';
import moment from 'moment';
import Constants, { SatelliteURLSuffix } from './constants';
import { AnalyticSatelliteType, SatelliteType } from '@/enums/satelliteType';

export async function getFeaturesForRange(
  from: string,
  to: string,
  bbox: string,
  satelliteSuffix?: string
): Promise<Feature<Polygon>[]> {
  return Axios.get(
    `https://services.sentinel-hub.com/ogc/wfs/${process.env.VUE_APP_SENTINEL_TOKEN_SAT_EXPLORER}?REQUEST=GetFeature&OUTPUTFORMAT=application/json&VERSION=1.1.2&CRS=EPSG:4326&BBOX=${bbox}&TIME=${from}/${to}&MAXCC=100&TYPENAMES=${satelliteSuffix}`
  ).then((response) => response?.data?.features || []);
}

export async function getScenes(
  daysBack: number,
  satelliteType: string,
  selectedBbox: string
): Promise<Feature<Polygon, { [name: string]: any }>[]> {
  const from = moment.utc().subtract(daysBack, 'days');
  const to = moment.utc();

  const ranges = [];
  while (from.isBefore(to)) {
    ranges.push({
      from: from.format(Constants.DATE_FORMAT),
      to: from.add(3, 'months').format(Constants.DATE_FORMAT)
    });
  }

  const tasks = ranges.map(({ from, to }) =>
    getFeaturesForRange(from, to, selectedBbox, SatelliteURLSuffix[satelliteType])
  );

  return Promise.all(tasks).then((result) => {
    return result.flat();
  });
}

export async function getDatesInfoFromSelectedLayer(
  daysBack: number,
  satelliteType: string,
  selectedBbox: string
): Promise<{
  dates: string[];
  cloudCoverPercentageDateMap: { [key: string]: string };
  sourceDateMap: Record<string, string>;
}> {
  let dates = [];
  let scenes: Feature<Polygon, { [name: string]: any }>[] = [];

  const isLandSat = satelliteType === SatelliteType.LANDSAT_7 || satelliteType === SatelliteType.LANDSAT_8;

  if (isLandSat) {
    const [LS8Scenes, LS7Scenes] = await Promise.all([
      getScenes(daysBack, SatelliteType.LANDSAT_8, selectedBbox),
      getScenes(daysBack, SatelliteType.LANDSAT_7, selectedBbox)
    ]);

    const LS8ScenesMarked = LS8Scenes.map((scene: Feature) => {
      return {
        ...scene,
        source: AnalyticSatelliteType.LANDSAT_8
      };
    });

    const LS7ScenesNotFoundInLS8Scenes = LS7Scenes.filter((LS7Feature: Feature) => {
      return LS8Scenes.every((LS8Feature: Feature) => {
        return LS7Feature.properties.date !== LS8Feature.properties.date;
      });
    });

    const LS7ScenesMarked = LS7ScenesNotFoundInLS8Scenes.map((scene: Feature) => {
      return {
        ...scene,
        source: AnalyticSatelliteType.LANDSAT_7
      };
    });

    scenes = [...LS8ScenesMarked, ...LS7ScenesMarked] as Feature | any;
  } else {
    scenes = await getScenes(daysBack, satelliteType, selectedBbox);
  }

  const cloudCoverPercentageDateMap: { [key: string]: string } = {};
  const sourceDateMap: Record<string, string> = {};
  scenes.forEach((feature: Feature | any) => {
    dates.push(feature.properties.date);

    if (
      cloudCoverPercentageDateMap[feature.properties.date] === undefined ||
      feature.properties.cloudCoverPercentage < cloudCoverPercentageDateMap[feature.properties.date]
    ) {
      cloudCoverPercentageDateMap[feature.properties.date] = feature.properties.cloudCoverPercentage;
      sourceDateMap[feature.properties.date] = feature.source;
    }
  });

  dates = Object.keys(cloudCoverPercentageDateMap);
  dates.sort((a: string, b: string) => (new Date(a).getTime() > new Date(b).getTime() ? 1 : -1));

  return { dates, cloudCoverPercentageDateMap, sourceDateMap };
}
