
import API from '@/services/api';
import Constants from '@/services/constants';
import { FieldDecoratorOptions, WrappedFormUtils } from 'ant-design-vue/types/form/form';
import { Component, Prop, Vue, Watch } from 'vue-property-decorator';

@Component
export default class SubmitForm extends Vue {
  @Prop() parcelId: string;
  @Prop() date: string;
  @Prop() startOf: string;
  @Prop() startOfSubClass: string;
  @Prop() endOf: string;
  @Prop() endOfSubClass: string;
  @Prop() isModalVisible: boolean;

  typeClassesMap: Record<string, string[]> = {};
  typeClasses: string[] = [];
  starOfSubClasses: string[] = [];
  endOfSubClasses: string[] = [];

  form: WrappedFormUtils;
  isFormVisible = false;

  submitDisabled = true;
  updateDisabled = true;
  dltDisabled = true;

  disabled = true;
  get decorators(): { [key: string]: FieldDecoratorOptions } {
    return {
      date: {
        initialValue: this.date ?? null,
        rules: [{ required: true }]
      },
      startOf: {
        initialValue: this.startOf ?? null,
        rules: [{ required: false }]
      },
      startOfSubClass: {
        initialValue: this.startOfSubClass ?? null,
        rules: [{ required: false }]
      },
      endOf: {
        initialValue: this.endOf ?? null,
        rules: [{ required: false }]
      },
      endOfSubClass: {
        initialValue: this.endOfSubClass ?? null,
        rules: [{ required: false }]
      }
    };
  }

  handleStartChange(v: any): void {
    this.form.setFieldsValue({ startOfSubClass: '' });
  }

  handleEndChange(v: any): void {
    this.form.setFieldsValue({ endOfSubClass: '' });
  }

  @Watch('isModalVisible')
  onModalToggle(): void {
    this.isFormVisible = this.isModalVisible;
  }

  onCancel() {
    this.$emit('closeForm', false);
    this.form.resetFields();
  }

  created(): void {
    API.getClassTypes().then((data) => {
      data.forEach((item) => {
        this.typeClassesMap[item.class] = item.subClasses;
      });

      this.typeClasses = Object.keys(this.typeClassesMap);
    });

    this.form = this.$form.createForm(this, {
      name: 'edit_event',
      onFieldsChange: (t, p, fields) => {
        const fieldsArray: { errors?: string[]; touched: boolean }[] = Object.values(fields);
        const errorExist = fieldsArray.some((field) => Array.isArray(field.errors));
        const formTouched = fieldsArray.some((field) => field.touched);
        this.submitDisabled = errorExist || !formTouched;
        this.updateDisabled = errorExist || !formTouched;
      }
    } as any);
  }

  beforeUpdate(): void {
    const { date, startOf, endOf, startOfSubClass, endOfSubClass } = this.form.getFieldsValue();

    this.starOfSubClasses = this.typeClassesMap[startOf || this.startOf];
    this.endOfSubClasses = this.typeClassesMap[endOf || this.endOf];

    if (this.date) {
      this.updateDisabled = !this.updateDisabled && (this.startOf || this.endOf) ? false : true;

      if (this.startOf || this.endOf) {
        this.submitDisabled = true;
      } else if (startOf || startOfSubClass || endOf || endOfSubClass) {
        this.submitDisabled = false;
      }

      if (!startOf && !endOf) {
        this.submitDisabled = true;
      }

      if (!date && (this.startOf || this.endOf)) {
        this.dltDisabled = false;
      } else if (this.date !== date) {
        this.dltDisabled = true;
      }
    }
  }

  onSave(action: string): void {
    this.form.validateFields(async (err, fieldsValue) => {
      if (!err) {
        let formValues = fieldsValue;
        formValues.parcelId = this.parcelId;
        // format new selected dates since existing ones are already formated
        if (typeof formValues.date != 'string') {
          formValues = {
            ...fieldsValue,
            date: fieldsValue['date'].format(Constants.DATE_FORMAT)
          };
        }
        this.$emit('handleEvent', formValues, action);
        this.form.resetFields();
      }
    });
  }
}
