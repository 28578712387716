import { Parcel } from '@/interfaces/parcel';
import { Survey } from '@/interfaces/survey';
import { AnalyticData } from '@/interfaces/analyticData';
import { AnalyticState } from '@/interfaces/analyticState';
import { State } from '@/interfaces/state';
import { Farm } from '@/interfaces/farm';
import { AnalyticType } from '@/enums/analyticType';
import { stringToMomentDate } from '@/services/date';
import { isAnalyticDataValid } from '@/services/analyticData';

export const getFilteredAnalyticData = (
  analyticData: AnalyticData[],
  analyticType: AnalyticType,
  parcelsList: string[]
): AnalyticData[] => {
  return analyticData.filter(
    (analytic: AnalyticData) => analytic.Type === analyticType && parcelsList.includes(analytic.ParcelID)
  );
};

export const getSurveyForSelectedParcelForAnalytic = (parcel: Parcel, filteredAnalyticData: AnalyticData[]) => {
  let survey = null;
  if (parcel) {
    const analyticsForParcel = filteredAnalyticData.filter(
      (analyticData: AnalyticData) => analyticData.ParcelID === parcel.id && isAnalyticDataValid(analyticData)
    );
    analyticsForParcel.forEach((analytic: AnalyticData) => {
      const surveyDateMoment = stringToMomentDate(analytic.SurveyDate);
      if (survey && surveyDateMoment.isBefore(survey.MomentDate)) {
        return;
      }
      survey = {
        id: analytic.SurveyID,
        ParcelID: analytic.ParcelID,
        Date: analytic.SurveyDate,
        MomentDate: surveyDateMoment,
        LastUpdate: analytic.LastUpdate,
        Analytics: [analytic.Type]
      } as Survey;
    });
  }
  return survey;
};

export const getSelectedSurveys = (
  selectedParcels: Parcel[],
  currentSelectedSurveys: Survey[],
  filteredAnalyticData: AnalyticData[]
) => {
  const surveys = [];
  selectedParcels.forEach((parcel: Parcel) => {
    let survey = currentSelectedSurveys.find((survey: Survey) => survey.ParcelID === parcel.id);
    if (!survey) {
      survey = getSurveyForSelectedParcelForAnalytic(parcel, filteredAnalyticData);
    }
    if (survey) {
      surveys.push(survey);
    }
  });
  return surveys;
};

export const getPreSelectFarm = (state: AnalyticState, rootState: State): Farm => {
  if (state.selectedParcels.length) {
    const farmIds = [...new Set(state.selectedParcels.map((parcel: Parcel) => parcel.FarmID))];
    let farm = null;
    if (rootState.selectedUnitHierarchy && farmIds.length === 1) {
      farm = rootState.selectedUnitHierarchy.Farms.find((farm) => farm.id === farmIds[0]) || null;
    }
    return farm;
  }
  return undefined;
};
