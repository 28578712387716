
import { PageName } from '@/enums/pageName';
import { Component, Vue, Watch } from 'vue-property-decorator';
import API from '@/services/api';
import Auth from '@/services/auth';
import constants from '@/services/constants';

@Component
export default class Menu extends Vue {
  pageName = PageName;
  query = {};
  defaultSelectedKeys = [this.pageName.HARVEST_BENCHMARK];
  selectedKeys: string[] = [];

  get logoClass(): string {
    return 'gamaya-logo';
  }

  isPageAlreadyActive(pageName: PageName): boolean {
    return this.$router.currentRoute.name === pageName;
  }

  mounted(): void {
    this.query = this.$route.query;
    this.selectedKeys = [this.$route.name];
  }

  @Watch('$route')
  onRouteChange(to): void {
    this.selectedKeys = [to.name];
  }

  public get isRegen(): boolean {
    return this.$router.currentRoute.name === PageName.REGEN;
  }

  async logout(): Promise<void> {
    localStorage.removeItem(constants.LOCAL_STORAGE_KEYS.SELECTED_COUNTRY_ID);
    localStorage.removeItem(constants.LOCAL_STORAGE_KEYS.SELECTED_ORGANIZATION_ID);
    localStorage.removeItem(constants.LOCAL_STORAGE_KEYS.SELECTED_UNIT_ID);
    localStorage.removeItem(constants.LOCAL_STORAGE_KEYS.SELECTED_FARM_ID);
    await API.logout();
    await Auth.doLogin();
  }
}
