
import { Component, Vue } from 'vue-property-decorator';
import Menu from '@/components/Menu.vue';

@Component({
  components: {
    Menu
  }
})
export default class Sidebar extends Vue {
  collapsed = true;

  toogleSidebar() {
    this.collapsed = !this.collapsed;
  }
}
