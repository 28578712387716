var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{ref:"pageContainer",staticClass:"page"},[_c('div',{staticClass:"map"},[_c('Mapbox',{attrs:{"access-token":"pk.eyJ1Ijoia2lycnVraXJydSIsImEiOiJjazJhMmJ6anMxMGh5M21tczJ6NTEwaW4yIn0.irfuud6XtRKV6K7hSv-bkQ","map-options":{
        container: 'map-container',
        style: {
          version: 8,
          sources: {},
          zoom: 19,
          layers: [],
          id: 'gamaya-regen'
        },
        tileSize: 256,
        preserveDrawingBuffer: true,
        pitchWithRotate: false
      },"nav-control":{ show: true, position: 'top-right' },"scale-control":{ show: true, position: 'bottom-right' }},on:{"map-load":_vm.onMapLoaded}}),_c('div',{staticClass:"controls"},[_c('div',{staticClass:"car-header"},[_c('b',[_vm._v("Car:")]),_vm._v(" "+_vm._s(_vm.car?.Properties && _vm.car.Properties.cod_imovel))]),_c('DatePicker',{attrs:{"dates":_vm.dates,"cloudCoverPercentageDateMap":_vm.cloudCoverPercentageDateMap,"selectedDate":_vm.selectedDate},on:{"on-change-date":_vm.selectDate}}),_c('LayerSelector',{attrs:{"selectedDate":_vm.selectedDate,"cloudCoverPercentageDateMap":_vm.cloudCoverPercentageDateMap},on:{"on-show-cloud-mask":_vm.onShowCloudMask,"on-update-cloud-transparency":_vm.onUpdateCloudTransparency,"on-change-layer":_vm.onChangeLayer}}),_c('a-checkbox',{on:{"change":_vm.changeLayer},model:{value:(_vm.isMimizeSHubUsage),callback:function ($$v) {_vm.isMimizeSHubUsage=$$v},expression:"isMimizeSHubUsage"}},[_vm._v("Minimize S-Hub Usage")])],1)],1),_c('Timeline',{attrs:{"dates":_vm.dates,"selectedDate":_vm.selectedDate,"cloudCoverPercentageDateMap":_vm.cloudCoverPercentageDateMap},on:{"on-change-date":_vm.selectDate}})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }